import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';

import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { variables } from '@/styles/variables/constant';

import useMutations from '../mutations/useMutations';
import useShallowRoute from '../useShallowRoute';

export type Branch = {
  name?: string;
  status?: string;
  id: number;
  created_by_id?: number;
  created_at?: string;
  created_by_name?: string;
  updated_at?: string;
  deleted_at?: null | string;
  company_id?: number;
  branch_type?: null | string;
  is_main?: boolean;
  slug?: string;
  retrieve_ids?: Record<string, Record<number, number>>;
};

// type for body cloneBranchAPI
export type CloneBranchBodyRequest = {
  model: {
    company_id: number;
    is_main: boolean;
    name: string;
    status: string;
    created_by_name: string;
    branch_type: string;
    slug: string;
  };
  //  parameter to disable cloning children for example [‘employees’] set to empty by default
  ignore_children: string[];
  retrieve_ids?: Record<string, number[]>;
};

export function useBranchesQuery(): UseQueryResult<Branch[], Error> {
  const { activeCompany, isLoggedIn } = useZustandStore(
    useShallow((state: ZustandState) => ({
      activeCompany: state.activeCompany,
      isLoggedIn: state.isLoggedIn,
    })),
  );
  return useQuery<Branch[]>({
    queryKey: [QUERYKEYS.BRANCHBYCOMPANYID, activeCompany?.id],
    queryFn: () => ApiUtils.getBranchByCompanyId(activeCompany?.id, {}),
    enabled: !!activeCompany && !!activeCompany?.id && !!isLoggedIn,
  });
}

const useBranches = () => {
  // use hooks definitions
  const { query } = useRouter();

  const queryClient = useQueryClient();
  const shallowRoute = useShallowRoute();
  const { updateBranch: updateBranchMutation, deleteBranch } = useMutations();
  const { activeBranch, setActiveBranch: setZustandActiveBranch } =
    useZustandStore(
      useShallow((state: ZustandState) => ({
        activeBranch: state.activeBranch,
        setActiveBranch: state.setActiveBranch,
      })),
    );

  const { data, refetch, ...queryData } = useBranchesQuery();

  /**
   * Sets the active branch, updating both Zustand store and cookies.
   *
   * @param newBranch - The new branch to set as the active branch. Must have a non-null `id` and a string `slug`.
   * @returns The new active branch.
   */
  const setActiveBranch = (
    newBranch: Branch & Pick<Required<Branch>, 'id' | 'slug'>,
  ): Branch & Pick<Required<Branch>, 'id' | 'slug'> => {
    if (
      (newBranch?.slug && newBranch.slug !== query?.branch) ||
      newBranch?.id !== activeBranch?.id
    ) {
      // update only if the slug is different
      shallowRoute({ branch: newBranch?.slug });
    }
    setCookie(variables.lucid_user_active_branch, newBranch);
    setZustandActiveBranch(newBranch);
    return newBranch;
  };

  /**
   * Clones the branch with the given ID.
   *
   * @param branchToCloneId - The ID of the branch to clone.
   * @param body - Optional body with details for the new cloned branch.
   * @returns The ID of the newly cloned branch.
   */
  async function cloneBranch(
    branchToCloneId: number,
    body?: CloneBranchBodyRequest,
  ): Promise<Branch> {
    const { cloneBranch: cloneBranchAPI } = ApiUtils;

    const newBranch = await cloneBranchAPI({
      id: branchToCloneId,
      body,
    });

    refetch().then(() => {
      setActiveBranch(newBranch);
      queryClient.invalidateQueries();
    });

    return newBranch;
  }

  /**
   * Updates the branch with the given body and returns the updated branch.
   *
   * @param body - The body containing the updated branch data.
   * @returns The updated branch object.
   */
  async function updateBranch(body: any): Promise<any> {
    const { updateBranch: updateBranchAPI } = ApiUtils;

    const updatedBranch = await updateBranchAPI(body);

    const newUpdatedBranch = updateBranchMutation.mutate({
      ...updatedBranch,
      id: body.id,
    });

    if (updatedBranch.id === activeBranch?.id) {
      setActiveBranch(updatedBranch);
    }
    queryClient.invalidateQueries();
    return newUpdatedBranch;
  }

  /**
   * Deletes the branch with the given ID.
   * If the branch being deleted is the active branch,
   * redirects to the first branch.
   *
   * @param branchId - The ID of the branch to delete.
   * @returns A promise that resolves when the delete is complete.
   */
  async function deleteBranchById(branchId: number): Promise<any> {
    const mainBranch = data?.find((branch: any) => branch.is_main);

    if (activeBranch?.id === branchId) {
      shallowRoute({ branch: mainBranch?.slug ?? 'main' });
    }

    return deleteBranch.mutate({ id: branchId as number });
  }

  return {
    ...queryData, // Passing down the query object from the useQuery hook
    activeBranch,
    branchesByCompanyId: data ?? [],
    deleteBranchById,
    refetchBranchByCompanyId: refetch,
    setActiveBranch,
    cloneBranch,
    updateBranch,
  };
};

export default useBranches;
