/* eslint-disable @typescript-eslint/no-shadow */
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import type { ITemplate } from '@/components/Settings/Template/components';
import dynamicRoute from '@/miscellaneous/constant';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { createAdjustedUTCDate } from '@/utils/dateUtils';
import useBranches from '@/utils/hooks/branches/useBranches';
import type { Models } from '@/utils/hooks/models/useModels';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { useChatStore } from '@/utils/hooks/useChatStore';
import queryClient from '@/utils/queryClient';
import { invalidateTemplateRelatedQueries } from '@/utils/types/templateUtils';
import AIItemView from '../AIItemView';
import { EXPLAIN_AI_PROMPT } from '../aiPrompts/aiPrompts';

/**
 * The `TemplateView` component is responsible for rendering a view that displays a list of models associated with a template.
 * It uses the `useChatStore` and `useZustandStore` hooks to access state and mutations related to the chat window, template management, and active company.
 * The component maps the models to a list of items with an id and title, and provides functionality to remove individual models, reset the models, and merge the models.
 * The `AIItemView` component is used to render the list of models and handle the various actions.
 */
const TemplateView = () => {
  const [models, setModels] = React.useState<any[]>([]);
  const {
    openChatWindow
  } = useChatStore(useShallow((state: any) => ({
    openChatWindow: state.openChatWindow
  })));
  const {
    t
  } = useTranslation();
  const {
    deleteModel
  } = useMutations();
  const router = useRouter();
  const {
    newAddedTemplate,
    setNewAddedTemplate,
    activeCompanyId,
    activeCompany,
    activeCompanySlug,
    activeBranchSlug
  } = useZustandStore(useShallow((state: ZustandState) => ({
    newAddedTemplate: state.newAddedTemplate,
    setNewAddedTemplate: state.setNewAddedTemplate,
    activeCompanyId: state.activeCompany?.id,
    activeCompany: state.activeCompany,
    activeCompanySlug: state.activeCompany?.slug,
    activeBranchSlug: state.activeBranch?.slug
  })));
  const {
    setAppLoader,
    appLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader,
    appLoader: state.appLoader
  })));
  const {
    cloneBranch,
    activeBranch
  } = useBranches();

  /**
   * Redirects the user to the model page for the active model.
   */
  const redirectToModel = (id: number) => {
    router.push(`/${activeCompanySlug}/${activeBranchSlug}/${dynamicRoute.models}/${id}`);
  };
  const {
    removeTemplate,
    applyTemplate
  } = useMutations();

  /**
   * Initializes the `models` state with the models from the `newAddedTemplate` if it exists.
   * This effect is used to ensure the models are updated when a new template is added.
   */
  useEffect(() => {
    if (newAddedTemplate && newAddedTemplate.models) {
      queryClient.invalidateQueries({
        queryKey: [QUERYKEYS.ALLFORMULAS, activeCompanyId]
      });
      setModels(newAddedTemplate.models);
    }
  }, [newAddedTemplate]);

  /**
   * Maps the models to a list of items with an id and title.
   * @returns {Object[]} - An array of objects with id and title properties, representing the models.
   */
  const items = models?.map((model: Models) => {
    return {
      id: model.id,
      title: model.name
    };
  });

  /**
   * Invalidates the cached models for the active company.
   * This function should be called after a model has been added, updated, or deleted to ensure the UI reflects the latest data.
   */
  const invalidateModels = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERYKEYS.ALLMODELS, activeCompanyId]
    });
  };

  /**
   * Resets the models state, removes the active template, and clears the newAddedTemplate state.
   * This function is called when the user wants to reset the models or when the last model is deleted.
   */
  const resetModels = (id?: number) => {
    setModels([]);
    removeTemplate.mutate({
      // @ts-ignore
      id: id || (newAddedTemplate?.active_template_id as number)
    }, {
      onSuccess: () => {
        invalidateModels();
        queryClient.invalidateQueries({
          queryKey: [QUERYKEYS.ACTIVETEMPLATES, activeCompanyId]
        });
      }
    });
    setNewAddedTemplate(null);
  };

  /**
   * Deletes the active template and updates the models state accordingly.
   *
   * If the deleted model was the last one, the `resetModels` function is called to reset the models state.
   * Otherwise, the models state is updated to remove the deleted model.
   *
   * @param {number} id - The ID of the model to be deleted.
   */
  const deleteActiveTemplate = (id: number) => {
    deleteModel.mutate({
      id
    });
    const removedModel = models?.find((model: Models) => model.id === id);
    if (removedModel) {
      if (models.length === 1) {
        resetModels();
      } else {
        setModels(models?.filter((model: Models) => model.id !== removedModel.id));
      }
    }
  };

  /**
   * Clears the `newAddedTemplate` state and resets the `models` state to an empty array.
   * This function is likely used to reset the state of the component when a new model is added or the last model is deleted.
   */
  const mergeModels = () => {
    setNewAddedTemplate(null);
    setModels([]);
  };

  /**
   * Redirects the user to the model with the specified ID.
   *
   * @param {number} id - The ID of the model to redirect to.
   */
  const onClickHandler = (id: number) => {
    redirectToModel(id);
  };

  /**
   * Merges the active models to a new scenario by cloning the active branch and applying a new template to the cloned branch.
   * This function sets the app loader to true before the merge operation and sets it to false after the operation is complete.
   */
  const mergeModelsToNewScenario = async () => {
    const {
      id,
      ...branchBody
    } = activeBranch;
    const addedTemplateId = (newAddedTemplate as ITemplate).id; // saving the id of the template to be removed

    // removing the template from the active branch
    await removeTemplate.mutateAsync({
      id: newAddedTemplate?.active_template_id
    });

    // creating a new branch with the same name as the template
    const newBranchBody = {
      model: {
        ...branchBody,
        is_main: false,
        name: `${activeBranch.name}-${newAddedTemplate?.name}`,
        status: 'active',
        slug: `${activeBranch.slug}-${newAddedTemplate?.name.toLocaleLowerCase().replaceAll(/[^a-zA-Z0-9]/g, '')}`
      },
      ignore_children: []
    };

    // cloning the active branch with the new branch body
    // @ts-ignore
    const clonedBranch = await cloneBranch(id, newBranchBody);

    // applying the removed template to the cloned branch
    await applyTemplate.mutateAsync({
      template_id: addedTemplateId,
      target_branch_id: clonedBranch.id,
      start_date: createAdjustedUTCDate({
        operations: [{
          method: 'startOf',
          unit: 'year'
        }],
        month: 1
      })
    });

    // resetting the states
    setModels([]);
    setNewAddedTemplate(null);
  };

  /**
   * Ensures that the app loader is set to false when a new template is added.
   *
   * This effect runs once, when the component mounts, and checks if the `appLoader` state is true and a `newAddedTemplate` exists. If both conditions are met, it sets the `appLoader` state to false.
   */
  useEffect(() => {
    if (appLoader && newAddedTemplate) setAppLoader(false);
  }, []);

  /**
   * Merges the active models to a new scenario by cloning the active branch and applying a new template to the cloned branch.
   * This function sets the app loader to true before the merge operation and sets it to false after the operation is complete.
   */
  const mergeToNewScenarioFunction = () => {
    setAppLoader(true);
    mergeModelsToNewScenario().finally(() => {
      setAppLoader(false);
      invalidateTemplateRelatedQueries(activeCompany);
    });
  };
  return <AIItemView title={t('template:template_view')} items={items} removeItems={deleteActiveTemplate} resetItems={resetModels} openChatWindow={openChatWindow} mergeItems={mergeModels} mergeToNewScenario={mergeToNewScenarioFunction} chatContentState={EXPLAIN_AI_PROMPT} onClickItem={onClickHandler} showDeleteOnAll optionColor={colors.desaturatedBlue} textColor={colors.white} data-sentry-element="AIItemView" data-sentry-component="TemplateView" data-sentry-source-file="TemplateView.tsx" />;
};
export default TemplateView;