import { Plus, Search } from 'lucide-react';
import type { MouseEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import Popup from 'reactjs-popup';
import DropdownItem from './components/DropdownItem';
import DropdownToggle from './components/DropdownToggle';
import EditableInput from './components/EditableInput';
import styles from './LinkDropdown.module.scss';

/**
 * Type defining the possible positions for the popup component.
 */
type PopupPosition = 'top left' | 'top center' | 'top right' | 'right top' | 'right center' | 'right bottom' | 'bottom left' | 'bottom center' | 'bottom right' | 'left top' | 'left center' | 'left bottom' | 'center center';

/**
 * Styles for the popup content. Sets border radius, background color,
 * box shadow, backdrop filter, border, width, padding, height and margins.
 */
const popupContentStyle = {
  borderRadius: '16px',
  backgroundColor: 'white',
  boxShadow: '0px 8px 20px 0px rgba(19, 25, 35, 0.15)',
  backdropFilter: 'blur(25px)',
  border: 'none',
  width: 'fit-content',
  padding: '12px',
  paddingBottom: '8px',
  height: 'fit-content',
  margin: '0px',
  marginTop: '10px'
};

/**
 * Props for the LinkDropdown component.
 * - items: Array of dropdown items with label, href and id.
 * - onAddClick: Optional click handler for add button.
 * - onEdit: Optional handler for editing an item.
 * - onDelete: Optional handler for deleting an item.
 * - header: Header text.
 * - checkedId: ID of checked item.
 * - onLinkClick: Optional click handler for items.
 * - color: Optional color.
 * - direction: Optional popup direction.
 * - link: Optional, show as link.
 * - text: Optional text.
 */
type DropdownProps = {
  items: {
    label: string;
    href: string;
    id: number;
    is_main?: boolean;
    icon?: JSX.Element;
  }[];
  onAddClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onEdit?: (id: number, newLabel?: string) => void; //  prop for edit function
  onDelete?: (id: number) => void; //  prop for delete function
  header: string;
  checkedId?: any;
  onLinkClick?: (item: any) => void;
  color?: string;
  direction?: PopupPosition;
  link?: boolean;
  text?: string;
  isEditMode?: boolean;
  style?: any;
  icon?: any;
  validInputChecker?: (value: string) => boolean;
  contentStyle?: any;
};

/**
 * Renders a dropdown item.
 * If edit mode is enabled for the item, renders an EditableInput.
 * Otherwise renders a DropdownItem.
 */
const ListItem = ({
  editMode,
  item,
  editValues,
  handleEdit,
  setEditValues,
  checkedId,
  handleLinkClick,
  getMenuItems,
  link,
  isEditMode,
  validInputChecker
}: {
  editMode: {
    [key: number]: boolean;
  };
  item: {
    label: string;
    href: string;
    id: number;
    is_main?: boolean;
  };
  editValues: {
    [key: number]: string;
  };
  handleEdit: (id: number, value: string) => void;
  setEditValues: any;
  checkedId: any;
  handleLinkClick: (item: any) => void;
  getMenuItems: (id: number, label: string) => any[];
  link?: boolean;
  isEditMode?: boolean;
  validInputChecker?: (value: string) => boolean;
}) => {
  return <li key={item.id} className={styles.dropdownItem} data-sentry-component="ListItem" data-sentry-source-file="LinkDropdown.tsx">
      {editMode[item.id] && isEditMode ? <EditableInput value={(editValues[item.id] as string)} id={item.id} handleEdit={handleEdit} setEditValues={setEditValues} editValues={editValues} validInputChecker={validInputChecker} /> : <DropdownItem item={item} checkedId={checkedId} handleLinkClick={
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (item: any) => {
      handleLinkClick(item);
    }} getMenuItems={getMenuItems} link={link} />}
    </li>;
};

/**
 * Renders a button to add a new item.
 *
 * @param onAddClick - Click handler for adding a new item
 * @param text - Text to display on the button
 */
const AddButton = ({
  onAddClick,
  text
}: {
  onAddClick: (event: MouseEvent<HTMLButtonElement>) => void;
  text: string;
}) => {
  return <li className={styles.dropdownHeader} data-sentry-component="AddButton" data-sentry-source-file="LinkDropdown.tsx">
      <button type="button" className={styles.addButton} onClick={onAddClick}>
        <Plus size={18} data-sentry-element="Plus" data-sentry-source-file="LinkDropdown.tsx" />
        <p className={styles.headerName}>{text}</p>
      </button>
    </li>;
};
const SearchItem = ({
  placeholder,
  handleSearch
}: {
  placeholder: string;
  handleSearch: (query: string) => void;
}) => {
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);
  return <li className={styles.searchContainer} data-sentry-component="SearchItem" data-sentry-source-file="LinkDropdown.tsx">
      <Search size={18} data-sentry-element="Search" data-sentry-source-file="LinkDropdown.tsx" />
      <input className={styles.searchInput} placeholder={placeholder} onChange={e => {
      setSearchValue(e.target.value);
    }} value={searchValue} />
      <IoCloseSharp size={20} onClick={() => setSearchValue('')} data-sentry-element="IoCloseSharp" data-sentry-source-file="LinkDropdown.tsx" />
    </li>;
};

/**
 * LinkDropdown component renders a dropdown with links/items.
 * Allows adding, editing, deleting items. Handles click outside to close.
 * Renders popup on click with items, add button.
 */
const LinkDropdown: React.FC<DropdownProps> = ({
  items,
  onAddClick,
  onEdit,
  onDelete,
  checkedId,
  header,
  onLinkClick,
  color,
  direction = 'bottom left',
  link,
  text,
  isEditMode = true,
  style,
  validInputChecker,
  contentStyle
}) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [isOpen, setIsOpen] = useState(false);
  const [editMode, setEditMode] = useState<{
    [key: number]: boolean;
  }>({});
  const [editValues, setEditValues] = useState<{
    [key: number]: string;
  }>({});
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleDropdown = () => setIsOpen(!isOpen);
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);
  useEffect(() => {
    // Function to check if the click is outside the dropdown
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setEditMode({});
      }
    }
    // Add event listener when the dropdown is open
    if (isOpen) {
      window.addEventListener('click', handleClickOutside);
    }
    // Remove event listener when the dropdown is closed or the component is unmounted
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]); // Only re-run if isOpen changes

  const enterEditMode = (id: number, label: string) => {
    setEditMode({
      ...editMode,
      [id]: true
    });
    setEditValues({
      ...editValues,
      [id]: label
    });
  };
  const handleLinkClick = (item: any) => {
    setIsOpen(false);
    if (onLinkClick) {
      onLinkClick(item);
      setIsOpen(false);
    }
  };
  const onHandleAddClick = async () => {
    const newItem = await (onAddClick as any)();
    if (newItem && newItem?.id) {
      setEditMode(prev => ({
        ...prev,
        [newItem.id]: true
      }));
    }
  };
  const handleEdit = async (id: number, value: string) => {
    if (onEdit) {
      await onEdit(id, value);
    }
    setEditMode({
      ...editMode,
      [id]: false
    });
  };
  const handleSearch = (query: string) => {
    if (!query) {
      setFilteredItems(items);
      return;
    }
    const newFilteredItems = items.filter(item => {
      return item?.label?.toLowerCase().includes(query.toLowerCase()) ?? false;
    });
    setFilteredItems(newFilteredItems);
  };
  const getMenuItems = (id: number, label: string, isNotEditable: boolean = false, isNotDeletable: boolean = false) => [{
    label: 'Edit',
    id,
    icon: <MdModeEdit width={15} color="black" />,
    onClick: () => {
      if (!isNotEditable) {
        enterEditMode(id, label);
        if (!isEditMode) {
          if (onEdit) {
            setIsOpen(false);
            onEdit(id);
          }
        }
      }
    },
    isNotEditable,
    isNotDeletable
  }, {
    label: 'Delete',
    id,
    icon: <MdDelete width={15} color="black" />,
    onClick: () => {
      if (onDelete && !isNotDeletable) {
        onDelete(id);
        setIsOpen(false);
      }
    },
    isNotEditable,
    isNotDeletable
  }
  // Add other items as needed
  ];
  return <div className={styles.dropdown} ref={dropdownRef} data-sentry-component="LinkDropdown" data-sentry-source-file="LinkDropdown.tsx">
      <Popup open={isOpen} trigger={<span>
            <DropdownToggle color={color} header={header} toggleDropdown={toggleDropdown} style={style} />
          </span>} position={direction} arrow={false} aria-describedby="linkDropdown" nested contentStyle={{
      ...popupContentStyle,
      ...contentStyle
    }} data-sentry-element="Popup" data-sentry-source-file="LinkDropdown.tsx">
        {
      // @ts-ignore
      (close: any) => <div className={styles.popupContent}>
              <SearchItem placeholder="Search" handleSearch={handleSearch} />

              <ul className={`${styles.dropdownList}`}>
                {filteredItems?.map(item => <ListItem key={item.id} editMode={editMode} item={item} editValues={editValues} handleEdit={handleEdit} setEditValues={setEditValues} checkedId={checkedId} handleLinkClick={handleLinkClick} getMenuItems={getMenuItems} link={link} isEditMode={isEditMode} validInputChecker={validInputChecker} />)}
              </ul>
              <AddButton onAddClick={() => {
          onHandleAddClick();
          close();
        }} text={(text as string)} />
            </div>}
      </Popup>
    </div>;
};
export default LinkDropdown;