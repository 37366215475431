import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import dynamicRoute from '@/miscellaneous/constant';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { variables } from '@/styles/variables/constant';

export const useIsLoggedIn = () => {
  const { isLoggedIn, setLoggedIn } = useZustandStore(
    (state: ZustandState) => state,
  );

  const router = useRouter();

  useEffect(() => {
    const userCookie = getCookie(variables.lucid_user) as string;
    // If there is, we parse it and set it as the login state.
    if (userCookie) {
      setLoggedIn(JSON.parse(userCookie));
    } else {
      setLoggedIn(null);
      if (router.pathname === undefined || router.pathname === null) return;
      if (
        router.pathname.includes(dynamicRoute.login) ||
        router.pathname === dynamicRoute.login ||
        router.pathname.includes(dynamicRoute.signup) ||
        router.pathname === dynamicRoute.signup ||
        router.pathname === dynamicRoute.accessError
      )
        return;

      router.push(dynamicRoute.login);
    }
  }, [router, router.pathname, setLoggedIn]);

  const setIsLoggedIn = (user: any) => {
    setLoggedIn(user);
    setCookie(variables.lucid_user, user);
  };

  return {
    isLoggedIn,
    setIsLoggedIn,
  };
};
