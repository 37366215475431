/* eslint-disable no-param-reassign */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */

import { useShallow } from 'zustand/react/shallow';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Models } from '@/utils/hooks/models/useModels';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { Report, ReportItem } from '@/utils/hooks/reports/reportTypes';
import { errorToaster } from '@/utils/toaster/toasters';
import type { Formula } from '@/utils/types/formulaTypes';
import type { Department, Employee, HiringPlan } from '@/utils/types/HiringTypes';
import type { TableRow, TableRowType } from '../types';
import { useTableContext } from './TableDnDHelpers/TableDNDContext';
import type { DraggableItem, DropPosition, HandleDropProps, OverRowHandler } from './TableDnDHelpers/TableDNDUtils';
import { checkIsAncestor, handleError, handleInvalidDrop, handleOutputOnOutputDrop, isFormula, isReportItem, isValidDrop, logDND, logDrop, outPosition, removeIdFromSortOrder, updateSortOrder, useGetSensors } from './TableDnDHelpers/TableDNDUtils';
const useDndTableLogic = (flatData: TableRow[]) => {
  // ########## Imports ###########
  const {
    branchOutputs
  } = DataStore((state: DataStoreState) => state);
  const {
    updateReportItem,
    updateReport,
    updateOutput,
    createReportItem,
    updateFormula,
    updateModels,
    createFormula,
    updateEmployee,
    updateHiringPlan
  } = useMutations();
  const {
    activeReport,
    activeReportItems,
    activeModel,
    activeModelFormulas,
    activeHiringPlan
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeReport: state.activeReport,
    activeReportItems: state.activeReportItems,
    activeModel: state.activeModel,
    activeModelFormulas: state.activeModelFormulas,
    activeHiringPlan: state.activeHiringPlan
  })));
  const sensors = useGetSensors();
  const {
    position,
    setOver,
    setDraggedItem,
    setPosition
  } = useTableContext();

  // ############# Functions ####################
  function checkIsAncestorReportItem(over: ReportItem, dropped: ReportItem): boolean {
    if (over.id === dropped.id) return true;
    if (over.parent_id === dropped.id) return true;
    if (!over?.parent_id) return false;
    const parent = (activeReportItems.find((item: ReportItem) => item.id === over.parent_id) as ReportItem);
    return checkIsAncestorReportItem(parent, dropped);
  }
  function updateReportOrReportItem(item: ReportItem | Report, body: any) {
    // if item has key report_id its a reportItem
    if (isReportItem(item)) {
      updateReportItem.mutate(body);
    } else {
      updateReport.mutate(body);
    }
  }
  function isObjectGroup(item: any) {
    if (isFormula(item)) {
      return (item as Formula).input_type === 'Group';
    }
    if (isReportItem(item)) {
      return (item as ReportItem).type === 'Section';
    }
    return false;
  }
  function updateFormulaOrModel(item: Formula | Models, body: any) {
    if (isFormula(item)) {
      // do optimistic update here
      updateFormula.mutate(body);
    } else {
      updateModels.mutate(body);
    }
  }
  async function handleDrop<T extends DraggableItem>({
    overItem,
    droppedItem,
    position,
    createItem,
    checkIsAncestor,
    updateItem,
    activeItems,
    activeContainer
  }: HandleDropProps<T>) {
    if (!droppedItem.id) {
      // If the item is new, create it first, then set droppedItem to the new data returned from API
      droppedItem = await createItem(droppedItem);
    }
    const overParentObject = overItem.parent_id ? activeItems.find((item: T) => item.id === overItem.parent_id) : activeContainer;
    const droppedParentObject = droppedItem.parent_id ? activeItems.find((item: T) => item.id === droppedItem.parent_id) : activeContainer;
    const sortById = overItem.id || '';
    let currentParent = overParentObject;
    const isNotOutposition = position !== outPosition;
    // const outpositionAndItemIsNotGroup =
    // position === outPosition && !isObjectGroup(droppedItem);
    // position === outPosition && !isObjectGroup(droppedItem); this is the condition for report items so its gneeric

    const isAncestor = checkIsAncestor(overItem, droppedItem);
    if (isAncestor && isNotOutposition) {
      console.log('🚀 ~ isAncestor row:', isAncestor);
      return 0;
    }
    if (isObjectGroup(overItem) && isNotOutposition
    // (isNotOutposition || outpositionAndItemIsNotGroup) this is used in report item case
    ) {
      currentParent = overItem;
    }
    const newParentID = currentParent && currentParent !== activeContainer ? currentParent.id : null;
    if (droppedParentObject) {
      if (droppedParentObject.sort_order && droppedParentObject.sort_order.includes(droppedItem.id?.toString() || '') && droppedParentObject.id !== currentParent?.id) {
        updateItem(droppedParentObject, {
          ...droppedParentObject,
          sort_order: removeIdFromSortOrder(droppedParentObject.sort_order || '', droppedItem.id?.toString() || '')
        });
      }
    }
    updateItem(droppedItem, {
      ...droppedItem,
      parent_id: newParentID
    });
    const newParentSortOrder = updateSortOrder(currentParent?.sort_order || '', position, sortById, droppedItem.id || ''
    // !currentParent || currentParent === activeContainer,
    );
    updateItem((currentParent as T), {
      ...currentParent,
      sort_order: newParentSortOrder
    });
    return 1;
  }
  async function handleReportItemDrop(overReportItem: ReportItem, droppedReportItem: ReportItem, position: DropPosition) {
    if (!droppedReportItem?.id) {
      // if the item is new, it needs to be created, then set droppedReportItem to the new item data returned from API
      await createReportItem.mutateAsync({
        ...droppedReportItem
        // any other fields you need to update
      }, {
        onSuccess: (data: ReportItem) => {
          droppedReportItem = data;
          // handle the rest of your logic here
        }
      });
    }
    const overParentObject = overReportItem?.parent_id ? activeReportItems.find((item: ReportItem) => item.id === overReportItem?.parent_id) : activeReport; // if has parent section, get the parent section, otherwise parent is the report
    const droppedParentObject = droppedReportItem?.parent_id ? activeReportItems.find((item: ReportItem) => item.id === droppedReportItem?.parent_id) : activeReport; // need to handle here when object is new, no need to update parent

    const isAncestor = checkIsAncestorReportItem(overReportItem, droppedReportItem);
    if (isAncestor) {
      // making sure we are not putting a parent as a child of its child
      if (logDND) console.log('🚀 ~ useDndTableLogic ~ isAncestor row:', isAncestor);
      return 0;
    }
    const sortById = overReportItem.id;
    let currentParent = overParentObject; // handleing the case of dropping on a non section

    // Handeling 2 cases. Dropping on seciton on outposition. Or dropping non reportitem object on an outposition of a section
    const isNotOutposition = position !== outPosition;
    const outpositonAndItemISNotSection = position === outPosition && droppedReportItem.type !== 'Section';
    if (overReportItem.type === 'Section' && (isNotOutposition || outpositonAndItemISNotSection)) currentParent = overReportItem;
    const newParentID = isReportItem(currentParent) ? currentParent?.id : null; // since if the new parent is the report itself, the reportItem parent is null

    if (droppedParentObject) {
      // removing sort order from previous parent
      if (droppedParentObject?.sort_order && droppedParentObject?.sort_order.includes(droppedReportItem?.id.toString()) && droppedParentObject.id !== currentParent?.id) {
        updateReportOrReportItem(droppedParentObject, {
          ...droppedParentObject,
          sort_order: removeIdFromSortOrder(droppedParentObject?.sort_order || '', droppedReportItem?.id?.toString() || '')
        });
      }
    }
    updateReportOrReportItem(droppedReportItem, {
      ...droppedReportItem,
      parent_id: newParentID
    });
    // sortById = currentParent.id === overReportItem.id ?
    const newParentSortOrder = updateSortOrder(currentParent?.sort_order || '', position, sortById, droppedReportItem.id || '', !isReportItem(currentParent) // if the parent is a section then we want to add new item to the bottom
    );

    // update the parent sort order
    updateReportOrReportItem((currentParent as ReportItem), {
      ...currentParent,
      sort_order: newParentSortOrder
    });
    return 1;
  }

  /**
   * This map is used to determine the overRowHandler to use for each table row type over rows. Meaning for each over row type we will use different handler to handle the drop on that type.
   */
  const overRowHandlerMap: { [key in TableRowType]: OverRowHandler } = {
    section: (over, dropped, position) => {
      if (!isValidDrop(over, dropped)) return handleInvalidDrop(over, dropped, position);

      // eslint-disable-next-line no-param-reassign
      // position = dropped.type !== 'section' ? 'middle' : position; // we are setting it to middle because non section items dropped on sections become their children

      const overRowObject = (over.baseRowItemParent as ReportItem);
      const droppedRowObject = (dropped.baseRowItemParent as ReportItem);
      const success = handleReportItemDrop(overRowObject, droppedRowObject, position);
      if (!success) {
        handleError(over, dropped);
      }
    },
    accountTree: (over, dropped, position) => {
      if (!isValidDrop(over, dropped)) return handleInvalidDrop(over, dropped, position);
      if (dropped.type === 'output') {
        const overRowObject = (over.original_row_object as Output);
        const droppedRowObject = (dropped.original_row_object as Output);
        // if its top level output block it since it cannot be a child of any output
        if (!droppedRowObject.parent_output) {
          errorToaster('Top Level outputs cannot be children of any output');
          return;
        }
        const successOutput = handleOutputOnOutputDrop(overRowObject, droppedRowObject, (position as DropPosition), updateOutput, branchOutputs);
        if (!successOutput) {
          handleError(over, dropped);
        }
      } else {
        const overRowObject = (over.baseRowItemParent as ReportItem);
        const droppedRowObject = (dropped.baseRowItemParent as ReportItem);
        const successReport = handleReportItemDrop(overRowObject, droppedRowObject, position);
        if (!successReport) {
          handleError(over, dropped);
        }
      }
    },
    output: (over, dropped, position) => {
      if (!isValidDrop(over, dropped)) return handleInvalidDrop(over, dropped, position);
      const overRowObject = (over.original_row_object as Output);
      const droppedRowObject = (dropped.original_row_object as Output);
      // if (!overRowObject.parent_output) position = 'middle'; // if it is a baseOutput then set it as direct child
      handleOutputOnOutputDrop(overRowObject, droppedRowObject, (position as DropPosition), updateOutput, branchOutputs);
    },
    gap: (over, dropped, position) => {
      if (logDND) logDrop(position, dropped, over);
    },
    total: (over, dropped, position) => {
      // do nothing for now
      if (logDND) logDrop(position, dropped, over);
      overRowHandlerMap.accountTree(over, dropped, position);
    },
    button: (over, dropped, position) => {
      // do nothing for now
      if (logDND) logDrop(position, dropped, over);
    },
    formulaGroup: (over, dropped, position) => {
      // do nothing for now
      if (!isValidDrop(over, dropped)) return handleInvalidDrop(over, dropped, position);

      // eslint-disable-next-line no-param-reassign
      // position = dropped.type !== 'section' ? 'middle' : position; // we are setting it to middle because non section items dropped on sections become their children

      const overRowObject = (over.original_row_object as Formula);
      const droppedRowObject = (dropped.original_row_object as Formula);
      if (overRowObject?.group !== droppedRowObject?.group) {
        return handleInvalidDrop(over, dropped, position);
      }
      const success = handleDrop<Formula>({
        overItem: overRowObject,
        droppedItem: droppedRowObject,
        position,
        createItem: createFormula,
        checkIsAncestor: (over, dropped) => checkIsAncestor<Formula>(over, dropped, activeModelFormulas),
        updateItem: updateFormulaOrModel,
        activeItems: activeModelFormulas,
        activeContainer: activeModel
      });
      if (!success) {
        handleError(over, dropped);
      }
    },
    formula: (over, dropped, position) => {
      // do nothing for now
      if (!isValidDrop(over, dropped)) return handleInvalidDrop(over, dropped, position);

      // eslint-disable-next-line no-param-reassign
      // position = dropped.type !== 'section' ? 'middle' : position; // we are setting it to middle because non section items dropped on sections become their children

      const overRowObject = (over.original_row_object as Formula);
      const droppedRowObject = (dropped.original_row_object as Formula);
      if (overRowObject.group !== droppedRowObject.group) {
        return handleInvalidDrop(over, dropped, position);
      }
      const success = handleDrop<Formula>({
        overItem: overRowObject,
        droppedItem: droppedRowObject,
        position,
        createItem: createFormula,
        checkIsAncestor: (over, dropped) => checkIsAncestor<Formula>(over, dropped, activeModelFormulas),
        updateItem: updateFormulaOrModel,
        activeItems: activeModelFormulas,
        activeContainer: activeModel
      });
      if (!success) {
        handleError(over, dropped);
      }
    },
    department: (over, dropped, position) => {
      // Handle employee being dropped on department
      if (dropped.type === 'employee' && over.type === 'department') {
        const droppedEmployee = (dropped.original_row_object as Employee);
        const targetDepartment = (over.department_name as Department);

        // If employee is dropped on department, take the first employee from that department and set it as the employee_sort_order above that item
        if (droppedEmployee && targetDepartment) {
          updateEmployee.mutate(({
            ...droppedEmployee,
            department: targetDepartment
          } as Employee));

          // Find the first
          const newSortOrder = updateSortOrder(activeHiringPlan?.employee_sort_order || '', 'top', (over.id as string), (droppedEmployee.id?.toString() as string));

          // Update the hiring plan with the new sort order
          updateHiringPlan.mutate(({
            ...activeHiringPlan,
            employee_sort_order: newSortOrder
          } as HiringPlan));
        }
        return;
      }

      // Handle department reordering
      const sourceDept = (dropped.department_name as Department);
      const targetDept = (over.department_name as Department);

      // If both source and target departments are valid
      if (sourceDept && targetDept) {
        // Update the department_sort_order based on the new sort order
        const newSortOrder = updateSortOrder(activeHiringPlan?.department_sort_order || '', position, targetDept, sourceDept);

        // Update the hiring plan with the new sort order
        updateHiringPlan.mutate(({
          ...activeHiringPlan,
          department_sort_order: newSortOrder
        } as HiringPlan));
      }
    },
    employee: (over, dropped, position) => {
      if (!isValidDrop(over, dropped)) {
        return handleInvalidDrop(over, dropped, position);
      }
      const overEmployee = (over.original_row_object as Employee);
      const droppedEmployee = (dropped.original_row_object as Employee);
      if (!overEmployee || !droppedEmployee) return;

      // If employee dropped on employee middle make it below it
      const newSortOrder = updateSortOrder(activeHiringPlan?.employee_sort_order || '', position, (overEmployee.id?.toString() as string), (droppedEmployee.id?.toString() as string));

      // Update the employee department if the over employee has a different department
      if (overEmployee.department !== droppedEmployee.department) {
        updateEmployee.mutate(({
          ...droppedEmployee,
          department: overEmployee.department
        } as Employee));
      }

      // Update the hiring plan with the new sort order
      updateHiringPlan.mutate(({
        ...activeHiringPlan,
        employee_sort_order: newSortOrder
      } as HiringPlan));
    }
  };
  const handleDragEnd = (event: any) => {
    const curPosition = (position as DropPosition);
    setPosition(null);
    setDraggedItem(null);
    setOver(null);
    const {
      active,
      over
    } = event;
    if (active?.id === over?.id) return; // we are not allowing a section to be dropped on itself
    const draggedRow = (flatData?.find((item: any) => item.id === active.id) as TableRow);
    const overRow = (flatData?.find((item: any) => item.id === over?.id) as TableRow);
    const overRowType = (overRow?.type as TableRowType);
    const overRowHandler = overRowHandlerMap[overRowType];
    if (!overRowHandler) {
      if (logDND) logDrop(position, active, over, draggedRow, overRow);
      return;
    }
    overRowHandler(overRow, draggedRow, (curPosition as DropPosition));
  };
  return {
    sensors,
    handleDragEnd
  };
};
export default useDndTableLogic;