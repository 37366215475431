type CallType = 'create' | 'update' | 'delete' | 'modify' | 'create_bulk' | 'update_bulk' | 'delete_bulk' | 'filter' | 'clone';

// convert generic Models to type models
type Models = 'branches' | 'companies' | 'dashboards' | 'employee_roles' | 'employees' | 'graphs' | 'hiring_plans' | 'models' | 'roles';
function getEndpoint(modelName: Models, callType: CallType) {
  switch (callType) {
    case 'create':
      return `${modelName}/create`;
    case 'update':
      return `${modelName}/update`;
    case 'delete':
      return `${modelName}/delete`;
    case 'modify':
      return `${modelName}/modify`;
    case 'create_bulk':
      return `${modelName}/create_bulk`;
    case 'update_bulk':
      return `${modelName}/update_bulk`;
    case 'delete_bulk':
      return `${modelName}/delete_bulk`;
    case 'filter':
      return `${modelName}/filter`;
    case 'clone':
      return `${modelName}/clone`;
    default:
      console.error('Value is not found');
      return null;
  }
}

/* APIs */
export const apiEndpoints = {
  getEndpoint,
  // function to get generic endpoints
  LOGIN: `auth/login`,
  SIGN_UP: `auth/signup`,
  GET_APIDECK_VAULT_TOKEN: `auth/get_apideck_vault_token`,
  NEW_TOKEN: `auth/get_new_token`,
  GAPI_USERINFO: `https://www.googleapis.com/oauth2/v3/userinfo`,
  GOOGLE_AUTH: `auth/google_login`,
  GET_FORMULAS: `formula/filter`,
  FORMULA_CREATE: `formula/create`,
  FORMULA_UPDATE: 'formula/update',
  FORMULA_CLONE: 'formula/clone',
  FORMULA_UPDATE_BULK: 'formula/update_bulk',
  FORMULA_DELETE: 'formula/delete',
  GET_ALL_COMPANIES: `companies/all_companies_by_user`,
  COMPANY_CREATE: `companies/create`,
  COMPANY_UPDATE: 'companies/update',
  COMPANY_DELETE: 'companies/delete',
  USER: `api/user`,
  COMPANY_BRANCH: `branches`,
  USERME: `auth/me`,
  REPORT_CREATE: `reports/create`,
  REPORT_UPDATE: 'reports/update',
  REPORT_ITEMS_UPDATE: 'report_items/update',
  REPORT_ITEMS_CREATE: 'report_items/create',
  REPORT_ITEMS_DELETE: 'report_items/delete',
  REPORT_DELETE: 'reports/delete',
  REPORT_FILTER: `reports/filter`,
  BRANCH_CREATE: `branches/create`,
  BRANCH_UPDATE: 'branches/update',
  BRANCH_DELETE: 'branches/delete',
  BRANCH_FILTER: `branches/filter`,
  BRANCH_CLONE: `branches/clone`,
  COMPANY_DETAILS: (id: string) => `api/company/${id}`,
  FORECAST_DETAILS: (id: string) => `api/forecast/${id}`,
  MODELS_CREATE: `models/create`,
  MODELS_UPDATE: `models/update`,
  MODELS_DELETE: `models/delete`,
  MODELS_FILTER: `models/filter`,
  GET_ALL_EMPLOYEES: `employees/filter`,
  GET_ALL_TEMPLATES: `templates/get_global_templates`,
  GET_ACTIVE_TEMPLATES: `templates/all_active`,
  GET_CUSTOM_TEMPLATES: `templates/filter`,
  APPLY_TEMPLATE: `templates/apply_template`,
  CREATE_TEMPLATE: `templates/create`,
  REMOVE_TEMPLATE: (id: string) => `templates/remove_active_template/${id}`,
  CREATE_ROLE: `roles/create`,
  GRAPHS: `graphs/filter`,
  CREATE_GRAPH: `graphs/create`,
  UPDATE_GRAPH: 'graphs/update',
  DELETE_GRAPH: 'graphs/delete',
  CREATE_GRAPH_BULK: 'graphs/create_bulk',
  OUTPUTS: `outputs/filter`,
  OUTPUTS_CREATE: `outputs/create`,
  OUTPUTS_UPDATE: `outputs/update`,
  OUTPUTS_DELETE: `outputs/delete`,
  DASHBOARD_CREATE: `dashboards/create`,
  DASHBOARD_FILTER: `dashboards/filter`,
  HIRING_PLAN_FILTER: `hiring_plans/filter`,
  HIRING_PLAN_UPDATE: `hiring_plans/update`,
  HIRING_PLAN_DELETE: `hiring_plans/delete`,
  HIRING_PLAN_CREATE: `hiring_plans/create`,
  BRANCH_HIRING_SETTINGS: `branch_hiring_settings/filter`,
  BRANCH_HIRING_SETTINGS_CREATE: `branch_hiring_settings/create`,
  BRANCH_HIRING_SETTINGS_UPDATE: `branch_hiring_settings/update`,
  GET_EMPLOYEE_ROLES_BY_COMPANY: `employee_roles`,
  EMPLOYEE_UPDATE: `employees/update`,
  EMPLOYEE_DELETE: `employees/delete`,
  EMPLOYEE_CREATE: `employees/create`,
  EMPLOYEE_CREATE_BULK: `employees/create_bulk`,
  EMPLOYEE_UPDATE_BULK: `employees/update_bulk`,
  EMPLOYEE_DELETE_BULK: `employees/delete_bulk`,
  ENTRY_FILTER: `entries/filter`,
  ENTRY_UPDATE: `entries/update`,
  ENTRY_DELETE: `entries/delete`,
  ENTRY_CREATE: `entries/create`,
  ENTRY_CREATE_BULK: `entries/create_bulk`,
  ENTRY_UPDATE_BULK: `entries/update_bulk`,
  ENTRY_DELETE_BULK: `entries/delete_bulk`,
  EMPLOYEE_COLUMNS: `employee_columns/filter`,
  CREATE_EMPLOYEE_COLUMNS: `employee_columns/create`,
  DELETE_EMPLOYEE_COLUMNS: `employee_columns/delete`,
  UPDATE_EMPLOYEE_COLUMNS: `employee_columns/update`,
  LIVE_ENTRY_FILTER: `live_entries/filter`,
  LIVE_ENTRY_UPDATE: `live_entries/update`,
  LIVE_ENTRY_DELETE: `live_entries/delete`,
  LIVE_ENTRY_CREATE: `live_entries/create`,
  AI_UTILS_HIRING_PLAN_GENERATE: `ai_utils/hiring_plan_generate`,
  AI_UTILS_HIRING_PLAN_EXPAND: `ai_utils/hiring_plan_expand`,
  AI_UTILS_HIRING_PLAN_DECREASE: `ai_utils/hiring_plan_decrease`,
  AI_UTILS_HIRING_PLAN_MODIFY: `ai_utils/hiring_plan_modify`,
  AI_UTILS_GET_PROMPT_TYPE: `ai_utils/orchestrator`,
  UPDATE_USER_DATES: `users/update_user_dates`,
  CREATE_INTEGRATION: `integration/create`,
  GET_INTEGRATIONS: `integration/filter`,
  UPDATE_INTEGRATION: `integration/update`,
  REMOVE_INTEGRATION: `integration/delete`,
  SYNC_INTEGRATION: `integration/sync`,
  AI_EXPLAINER: `ai_utils/explainer`,
  API_DECK_CONNECTORS: `integration/get_api_deck_connectors`,
  VALIDATE_QUESTIONS_AND_ANSWERS: 'ai_utils/validate_q_list_answered',
  AI_EXPAND_TEXT: 'ai_utils/text_expand_according_to_q_list',
  TEMPLATE_ASSIGNER: 'ai_utils/template_assigner',
  FINISH_ONBOARDING: 'companies/finish_onboarding',
  EXTRACT_FIELDS_COMPANY_DESCRIPTIONS: 'ai_utils/extract_fields_from_company_descriptions',
  EXTRACT_GOALS_COMPANY_DESCRIPTIONS: 'ai_utils/extract_goals_from_company_descriptions',
  PREDICT_COMPANY_PRODUCTS: 'ai_utils/predict_company_products',
  SCRAPE_COMPANY: 'companies/scrape_company_data',
  FAST_ONBOARDING: 'companies/fast_onboarding',
  MODELS_UPDATE_BULK: 'models/update_bulk',
  SEND_ERROR_REPORT: 'auth/error',
  AI_VOICE_RESPONSE: 'ai_utils/get_ai_voice_response',
  GET_PROMPT_SUMMARY: 'ai_utils/get_prompt_summary'
};