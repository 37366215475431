import Cleave from 'cleave.js/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';
import { formatNumber } from '@/utils/helpers/formattingHelper';
import type { Formula } from '@/utils/types/formulaTypes';
import type { TableRow } from '../types';
import { BaseCellType } from '../types';
import { getInitialValue } from '../utils';
import { defaultKeyDownHandler } from './baseCellUtils';
import CellDatePicker from './cellDatePicker';
import CurrencyCell from './currencyCell';
// eslint-disable-next-line import/no-cycle
import FinancialViewCellComponent from './financialViewCell/FinancialViewCellComponent';
import FormulaCellComponent from './formulaCell/index';
// eslint-disable-next-line import/no-cycle
import FormulaViewCellComponent from './formulaCellView';
import GraphCellComponent from './graphCell/GraphCellComponent';
// eslint-disable-next-line import/no-cycle
import InputFormulaCell from './inputCells/InputFormulaCell';
import FolderCellComponent from './outputCell/FolderCellComponent';
// eslint-disable-next-line import/no-cycle
import OutputCellComponent from './outputCell/OutputCellComponent';

/**
 * Props for the EditCell.
 * @typedef {Object} EditCellProps
 * @property {Object} columnSpec - The react-table column specification object.
 * @property {Object} cell - The react-table cell object.
 * @property {Object} row - The react-table row object.
 */

export interface EditCellProps {
  columnSpec?: any;
  cell?: any;
  row?: any;
  defaultValue?: any;
  // eslint-disable-next-line react/no-unused-prop-types
  focus?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
}
/**
 * Represents an editable cell component.
 * @param {Object} props - Component properties.
 * @param {EditCellProps} props - Properties specific to the EditCell component.
 * @param {Cell} props.cell - The cell being edited.
 * @param {Row} props.row - The row to which the cell belongs.
 * @param {ColumnSpec} props.columnSpec - Specifications for the column.
 * @returns {JSX.Element} - Returns a JSX element for an input field.
 */
export const EditCell: React.FC<EditCellProps> = ({
  cell,
  row,
  columnSpec,
  defaultValue,
  focus,
  className
}: EditCellProps) => {
  const {
    style,
    type
  } = columnSpec;
  const isCheckbox = type === BaseCellType.Checkbox;

  // Set initial value for input field and checkbox state
  const [value] = useState(() => defaultValue || getInitialValue(cell.getValue(), type));
  const [isChecked] = useState(isCheckbox ? cell.getValue() : false);
  // Create handler functions from columnSpec.handlerFunctions
  const {
    onKeyDown,
    ...actualHandlerFunctions
  } = Object.keys(columnSpec.handlerFunctions).reduce((acc: any, key: any) => {
    acc[key] = columnSpec.handlerFunctions[key](cell, row);
    return acc;
  }, {});
  return <input type={type} defaultValue={value} defaultChecked={isChecked} onKeyDown={defaultKeyDownHandler(onKeyDown)} {...actualHandlerFunctions} // Use the actual handler functions here
  style={{
    ...style,
    height: '100%',
    textAlign: 'left'
  }} className={className || styles.baseCell}
  // eslint-disable-next-line jsx-a11y/no-autofocus
  autoFocus={focus} data-sentry-component="EditCell" data-sentry-source-file="baseCells.tsx" />;
};

/**
 * Editable cell component for float (number) data type.
 *
 * @component
 * @param {object} props - Component properties.
 * @returns {ReactElement} - Editable cell input for numbers.
 */
export const EditFloatCell: React.FC<EditCellProps> = (props: EditCellProps) => {
  const defaultValue = props.defaultValue || getInitialValue(props.cell.getValue(), props.cell.type);
  const actualHandlerFunctions = Object.keys(props.columnSpec.handlerFunctions).reduce((acc: any, key: any) => {
    acc[key] = props.columnSpec.handlerFunctions[key](props.cell, props.row);
    return acc;
  }, {});
  const {
    style
  } = props.columnSpec;
  return <Cleave style={style} value={defaultValue} className={props.className || clsx(styles.baseCell, 'grow')} options={{
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalScale: 3
  }} onKeyDown={(e: any) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  }} aria-label={props.columnSpec.type} {...actualHandlerFunctions} autoFocus={props.focus} data-sentry-element="Cleave" data-sentry-component="EditFloatCell" data-sentry-source-file="baseCells.tsx" />;
};

/**
 * Editable cell component for date data type.
 *
 * @component
 * @param {object} props - Component properties.
 * @returns {ReactElement} - Editable cell input for dates.
 */
const EditDateCell: React.FC<EditCellProps> = ({
  cell,
  row,
  columnSpec,
  defaultValue
}: EditCellProps) => {
  const {
    type,
    style
  } = columnSpec;

  // Set initial value for input field and checkbox state
  const [value] = useState(() => defaultValue || getInitialValue(cell.getValue(), type));
  return <CellDatePicker allowReset cell={cell} row={row} columnSpec={columnSpec} defaultValue={value} style={type === 'formula' ? {} : style} data-sentry-element="CellDatePicker" data-sentry-component="EditDateCell" data-sentry-source-file="baseCells.tsx" />;
};

/**
 * Editable cell component for currency data type.
 *
 * @component
 * @param {object} props - Component properties.f
 * @returns {ReactElement} - Editable cell input for currency values.
 */
const EditCurrencyCell: React.FC<EditCellProps> = ({
  cell,
  row,
  columnSpec,
  defaultValue,
  focus,
  className
}) => {
  const {
    type,
    style
  } = columnSpec;
  // Set initial value for input field and checkbox state
  const [value] = useState(() => defaultValue || getInitialValue(cell.getValue(), type));
  return <CurrencyCell cell={cell} row={row} columnSpec={columnSpec} defaultValue={value} style={style} focus={focus} className={className} data-sentry-element="CurrencyCell" data-sentry-component="EditCurrencyCell" data-sentry-source-file="baseCells.tsx" />;
};

/**
 * Editable cell component for currency data type.
 *
 * @component
 * @param {object} props - Component properties.
 * @returns {ReactElement} - Editable cell input for currency values.
 */
const EditCheckboxCell: React.FC<any> = (props: any) => {
  // this cell needs to handle converting currency to number and back
  // This cell needs to handle displaying empty currency cell as well $0.00 , and typing inside should start on the left side not .00 side
  return <EditCell {...props} data-sentry-element="EditCell" data-sentry-component="EditCheckboxCell" data-sentry-source-file="baseCells.tsx" />;
};

/**
 * View-only cell component.
 *
 * @component
 * @param {object} props - Component properties.
 * @param {object} props.cell - The cell data.
 * @param {object} props.columnSpec - The column specification.
 * @param {string} props.defaultValue - The default value.
 * @param {boolean} props.roundValue - Whether to round the value.
 * @returns {ReactElement} - View-only cell input.
 */
export const ViewCell: React.FC<any> = ({
  cell,
  columnSpec,
  defaultValue,
  roundValue,
  className
}: {
  cell: any;
  columnSpec: any;
  defaultValue?: any;
  roundValue?: boolean;
  className?: string;
}) => {
  let value = defaultValue || cell.getValue();

  // Check if value is a number or a string that can be parsed to a number
  if (roundValue && value) {
    if (typeof value === 'number') {
      value = Math.round(value);
    } else if (typeof value === 'string' && !Number.isNaN(parseFloat(value))) {
      value = Math.round(parseFloat(value));
    }
  }
  const cellValue = cell?.row?.original?.input_type === 'Group' && value === '0' ? '' : formatNumber(value);
  return <div style={{
    ...columnSpec?.style,
    ...cell?.row?.original.rowStyle
  }} className={className} data-sentry-component="ViewCell" data-sentry-source-file="baseCells.tsx">
      <span className={styles.viewCellContent}>{cellValue}</span>
    </div>;
};
const OutputCell: React.FC<any> = ({
  row
}: any) => {
  return <OutputCellComponent row={row} data-sentry-element="OutputCellComponent" data-sentry-component="OutputCell" data-sentry-source-file="baseCells.tsx" />;
};
const PercentageCell: React.FC<any> = ({
  row,
  cell,
  columnSpec,
  defaultValue,
  focus
}: EditCellProps) => {
  const {
    style
  } = columnSpec;
  const newDefaultValue = defaultValue || cell.getValue();
  return <div className="h-full w-full" data-sentry-component="PercentageCell" data-sentry-source-file="baseCells.tsx">
      <CurrencyCell cell={cell} row={row} columnSpec={columnSpec} defaultValue={`${newDefaultValue * 100}`} style={style} symbol="%" symbolPosition="right" focus={focus} data-sentry-element="CurrencyCell" data-sentry-source-file="baseCells.tsx" />
    </div>;
};

/**
 * Renders a formula cell component based on the row's context.
 *
 * @component
 * @param {object} props - Component properties.
 * @param {object} props.row - The current row data.
 * @param {object} props.cell - The current cell data.
 * @param {object} props.columnSpec - The specification for the current column.
 * @returns {ReactElement} - A formula cell component or an input cell component or an output cell component based on the row's context.
 */
const FormulaCell: React.FC<any> = ({
  row,
  cell,
  columnSpec
}: any) => {
  const originalRow = (row.original as TableRow);
  const rowFormula = (originalRow.original_row_object as Formula);

  // Conditions for cell rendering
  const isOutputCell = originalRow?.type === 'output';
  const isInputCell = rowFormula?.group === 'Inputs';
  const isGroupRow = originalRow?.input_type === 'Group' && !isOutputCell;
  return <>
      {/* Render an empty div for button type cell */}
      {originalRow.type === 'button' ? <div className="h-full w-full" /> : <>
          {/* Render FolderCellComponent for group rows */}
          {isGroupRow ? <FolderCellComponent row={row} /> : <>
              {/* Render InputFormulaCell if it's an input cell */}
              {isInputCell ? <InputFormulaCell row={row} cell={cell} columnSpec={columnSpec} /> : <>
                  {/* Render OutputCell if it's an output cell, otherwise render FormulaCellComponent */}
                  {isOutputCell ? <OutputCell row={row} /> : <FormulaCellComponent row={row} cell={cell} columnSpec={columnSpec} />}
                </>}
            </>}
        </>}
    </>;
};
const FormulaViewCell: React.FC<any> = ({
  row,
  cell,
  columnSpec
}: any) => {
  // Check if the cell is an output cell
  const isOutputCell = !!row.original.output_id && row.parentId?.split('-')[0] === 'Outputs';

  // Check if the cell is a button row
  const isButtonRow = row.original.type === 'button';
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isOutputCell && !isButtonRow ? <FormulaViewCellComponent row={row} cell={cell} columnSpec={columnSpec} /> : <ViewCell cell={cell} columnSpec={columnSpec} />}
    </>
  );
};

/**
 * A React functional component that renders a financial view cell.
 *
 * @param {object} props - The component props.
 * @param {any} props.cell - The cell data.
 * @param {any} props.columnSpec - The column specification.
 * @returns {React.ReactElement} - The rendered financial view cell.
 */
const FinancialViewCell: React.FC<any> = ({
  cell,
  columnSpec
}: any) => {
  const {
    row,
    column
  } = cell;

  /**
   * Creates a new cell object with the specified properties.
   *
   * @param {any} row - The row data object that contains the data to be displayed in the cell.
   * @param {any} column - The column object that contains the configuration for the cell.
   */
  const newCell = {
    id: row.original.id,
    column_name: column.columnDef.column_name,
    Header: column.columnDef.Header,
    subRows: row.original.subRows,
    isTotalRow: row.original.isTotalRow,
    columnType: column.columnDef.columnType,
    accessorKey: column.columnDef.accessorKey,
    data: cell.row,
    value: cell?.getValue()
  };
  return <FinancialViewCellComponent cell={newCell} data-sentry-element="FinancialViewCellComponent" data-sentry-component="FinancialViewCell" data-sentry-source-file="baseCells.tsx">
      <ViewCell cell={cell} columnSpec={columnSpec} roundValue data-sentry-element="ViewCell" data-sentry-source-file="baseCells.tsx" />
    </FinancialViewCellComponent>;
};

/**
 * A React functional component that renders a graph cell in a table.
 *
 * @param {any} row - The row data object that contains the data to be displayed in the graph cell.
 * @returns {React.ReactElement} - A React element that represents the graph cell.
 */
const GraphCell: React.FC<any> = ({
  row
}: any) => {
  return <GraphCellComponent row={row} data-sentry-element="GraphCellComponent" data-sentry-component="GraphCell" data-sentry-source-file="baseCells.tsx" />;
};
interface CellComponentMap {
  [BaseCellType.Number]: React.FC<BaseCellType.Number | any>;
  [BaseCellType.Text]: React.FC<BaseCellType.Text | any>;
  [BaseCellType.Date]: React.FC<BaseCellType.Date | any>;
  [BaseCellType.Currency]: React.FC<BaseCellType.Currency | any>;
  [BaseCellType.View]: React.FC<BaseCellType.View | any>;
  [BaseCellType.Checkbox]: React.FC<BaseCellType.Checkbox | any>;
  [BaseCellType.Formula]: React.ComponentType<BaseCellType.Formula | any>;
  [BaseCellType.Output]: React.ComponentType<BaseCellType.Output | any>;
  [BaseCellType.Percentage]: React.ComponentType<BaseCellType.Percentage | any>;
  [BaseCellType.FormulaView]: React.ComponentType<BaseCellType.FormulaView | any>;
  [BaseCellType.FinancialView]: React.ComponentType<BaseCellType.FinancialView | any>;
  [BaseCellType.Graph]: React.ComponentType<BaseCellType.Graph | any>;
}
export const cellComponentMap: CellComponentMap = {
  [BaseCellType.Number]: EditFloatCell,
  [BaseCellType.Text]: EditCell,
  [BaseCellType.Checkbox]: EditCheckboxCell,
  [BaseCellType.Date]: EditDateCell,
  [BaseCellType.Currency]: EditCurrencyCell,
  [BaseCellType.View]: ViewCell,
  [BaseCellType.Formula]: FormulaCell,
  [BaseCellType.Output]: OutputCell,
  [BaseCellType.Percentage]: PercentageCell,
  [BaseCellType.FormulaView]: FormulaViewCell,
  [BaseCellType.FinancialView]: FinancialViewCell,
  [BaseCellType.Graph]: GraphCell
};

/**
 * Get the appropriate cell component based on the data type.
 *
 * @function
 * @param {BaseCellType} type - Data type of the cell (e.g., 'number', 'date', 'currency').
 * @returns {React.FC} - Corresponding cell component.
 */
export const getCellComponentByType = (type: BaseCellType): any => {
  return cellComponentMap[type] || EditCell;
};