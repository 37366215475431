import numeral from 'numeral';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { getMonthYearFromIsoString } from '@/utils/dateUtils';
import type { Formula, FormulaGroupType } from '@/utils/types/formulaTypes';
import applyStylesToColumns from '../shared/columns/applyStylesToColumns';
// eslint-disable-next-line import/no-cycle
import columnFactory from '../shared/columns/columnFactory';
import sharedColumnSettings, { mergeSettings } from '../shared/TableHelpers/sharedColumnSettings';
import { SidebarCellType } from '../shared/types';
import { getDateFormulaString, getOptions } from './ModelTableUtils';
import settings from './settings';

/**
 * Generates an array of column specifications for a hiring plan based on the provided data and settings.
 *
 * @function
 * @param {Object} params - Parameters for column generation.
 * @param {Object} params.data - The data object used to determine column specifications.
 * @returns {Object[]} - An array of column specifications for the hiring plan.
 */
const ModelsColumnGenerator = (hooks: any, dates: string[], page?: string): any[] => {
  const {
    createFormula,
    deleteFormula,
    updateFormula,
    setNewRowIndex,
    updateModels
  } = hooks;
  // const { createFormula, deleteFormula, updateFormula } = useMutations();

  const {
    activeModel: model,
    activeBranch
  } = useZustandStore.getState();
  const branchId = activeBranch?.id;
  const currentSortOrder = model.sort_order || ''; // '1212|12121|243434|3434'

  /**
   * Creates a function that will add a new formula when called.
   * The new formula will be added to the specified formula group type with default values.
   */

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const addNewFormula = (row: any, modelId: number, isFolder?: boolean) => {
    const formulaGroupType: FormulaGroupType = row.original.group;
    const formulaName = formulaGroupType === 'Inputs' ? 'Input' : 'Calculation';
    return createFormula.mutate({
      branch_id: branchId,
      name: `New ${formulaName}`,
      group: formulaGroupType,
      model_id: row.original?.model_id ? row.original?.model_id : modelId,
      input_type: 'Number',
      parent_id: isFolder ? row.original?.original_row_object?.id : null
    }, {
      onSuccess: (data: any) => {
        const newSortOrder = currentSortOrder ? `${currentSortOrder}|${data.id}` : `${data.id}`;
        updateModels.mutate({
          ...model,
          sort_order: newSortOrder
        });
        setNewRowIndex(data.id);
        if (isFolder) {
          row.toggleExpanded(true);
        }
      }
    });
  };
  const deleteFormulaById = (formulaId: number) => {
    const sortOrderArray = currentSortOrder.split('|');
    const filteredArray = sortOrderArray.filter(id => +id !== formulaId);
    const newSortOrder = filteredArray.length > 0 ? filteredArray.join('|') : '';
    deleteFormula.mutate({
      id: formulaId
    }, {
      onSuccess: () => {
        updateModels.mutate({
          ...model,
          sort_order: newSortOrder
        });
      }
    });
  };

  /**
   * Updates a formula by its ID.
   *
   * Calls the `updateFormula` mutation to update the formula record
   * matching the given ID. The formula value is formatted based on the
   * original formula's input type (either a date or a raw value).
   *
   * @param {any} original - The original formula object.
   * @param {any} value - The new value to update the formula with.
   */
  const updateFormulaById = (original: any, value: any) => {
    const formulaValue = original?.input_type === 'Date' ? getDateFormulaString(value) : numeral(value).value()?.toString();
    updateFormula.mutate({
      group: original?.group,
      input_type: original?.input_type,
      model_id: original?.model_id,
      branch_id: original?.branch_id,
      name: original.name,
      parent_id: original?.parent_id,
      expression_string: JSON.stringify([{
        start: '',
        end: '',
        formula: formulaValue
      }]),
      emoji: original?.emoji,
      id: original?.formula_id,
      output_id: original?.output_id
    });
  };
  const cloneFormula = (formula: Formula) => {
    return createFormula.mutate({
      ...formula,
      output_id: null
    }, {
      onSuccess: (data: any) => {
        setNewRowIndex(data.id);
      }
    });
  };
  const mergedSettings = mergeSettings({
    ...sharedColumnSettings,
    styles: {
      ...(sharedColumnSettings.styles ?? {}),
      shared: {
        ...sharedColumnSettings.styles?.shared,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      },
      individual: [{
        accessorKey: 'sidebar',
        // Replace undefined with an empty string or provide the correct value
        style: {
          ...sharedColumnSettings.styles?.individual[0]?.style,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: 'none'
        }
      }]
    }
  }, settings);

  /**
   * Generates the sidebar header text based on the current page.
   *
   * @param page - The current page being displayed.
   * @returns The sidebar header text.
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const sidebarHeaderTextGenerator = (page: string) => {
    switch (page) {
      case 'assumptions':
        return 'Model Inputs';
      default:
        return model?.name;
    }
  };
  let init = [
  // Default department column
  {
    id: 'sidebar',
    accessorKey: 'sidebar',
    header: sidebarHeaderTextGenerator(page ?? ''),
    type: SidebarCellType.ModelsTable,
    getOptions: (row: any, activeModelId: number, setIsRenaming?: any) => {
      const originalFormula = (row.original.original_row_object as Formula);
      const isGroupFormula = row.original.input_type === 'Group';
      const options = getOptions(() => addNewFormula(row, activeModelId), {
        title: originalFormula?.group || '',
        action: () => addNewFormula(row, activeModelId, true)
      }, () => deleteFormulaById(originalFormula.id), () => setIsRenaming(true), () => cloneFormula(originalFormula));
      return !isGroupFormula ? options.slice(1) : options;
    },
    handlerFunctions: {}
  }, {
    id: 'graph',
    accessorKey: 'graph',
    header: 'Graph',
    enableEditing: true,
    type: 'graph',
    handlerFunctions: {},
    headerStyle: {
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: '700',
      padding: '0 16px'
    },
    isSubTitle: false,
    isBorderTop: false
  }, {
    id: 'formula',
    accessorKey: 'formula',
    header: 'Formula',
    enableEditing: true,
    type: 'formula',
    handlerFunctions: {
      /**
       * Updates the formula for a row in the table when the formula input loses focus.
       *
       * @param _cell - The cell object for the formula column.
       * @param _row - The row object containing the formula to be updated.
       * @returns A function that updates the formula for the given row when the input loses focus.
       */
      onBlur: (_cell: any, _row: any) => (_e: any) => {
        updateFormulaById(_row.original, _e.target.value);
      },
      onKeyDown: (_cell: any, _row: any) => (_e: any) => {
        if (_e.key === 'Enter') {
          _e.currentTarget.blur();
        }
      }
    },
    headerStyle: {
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: '700',
      padding: '0 16px'
    },
    isSubTitle: false,
    isBorderTop: false
  }, ...dates.map(date => ({
    id: date,
    // the field name is set as id of the
    accessorKey: date,
    header: getMonthYearFromIsoString(date),
    enableEditing: false,
    type: 'formulaView',
    handlerFunctions: {},
    headerStyle: {
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: '700',
      padding: '0 16px'
    },
    isSubTitle: false,
    isBorderTop: false
  })),
  // Include additional columns
  ...mergedSettings.additionalColumns];

  // apply styles to affected column(s)
  init = init.map(column => applyStylesToColumns(column, mergedSettings));
  // apply column factory to each column
  return init.map(columnFactory);
};
export default ModelsColumnGenerator;