import { useState } from 'react';

import useMutations from './mutations/useMutations';
import useTextToSpeech from './useTextToSpeech';

/**
 * A custom React hook that provides functionality for speaking prompt text using text-to-speech.
 *
 * The `usePromptTextToSpeech` hook returns an object with two properties:
 * - `speakPromptText`: An asynchronous function that takes in user input, response, prompt type, and prompt operation, retrieves a prompt summary, and then speaks the prompt text using text-to-speech.
 * - `isLoading`: A boolean that indicates whether the `speakPromptText` function is currently in progress.
 */
export const usePromptTextToSpeech = () => {
  const { getPromptSummary } = useMutations();
  const { textToSpeech } = useTextToSpeech();
  const [isLoading, setIsLoading] = useState(false);

  const convertPromptToSpeech = async (
    user_input: string,
    response: string,
    prompt_type: string,
    prompt_operation: string,
  ) => {
    setIsLoading(true);
    try {
      const promptText = await getPromptSummary.mutateAsync({
        user_input,
        response,
        prompt_type,
        prompt_operation,
      });

      if (promptText.response) {
        await textToSpeech(promptText.response);
      }

      return promptText.response;
    } catch (error) {
      console.error('Error in getting prompt summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { convertPromptToSpeech, isLoading };
};
