import type { Company } from './hooks/company/useCompany';
import { errorToaster } from './toaster/toasters';

/**
 * Scrapes the company data from the provided URL and updates the active company in the application state.
 *
 * @param url - The URL of the company website to scrape.
 * @param setAppLoader - A function to set the loading state of the application.
 * @param setActiveCompany - A function to set the active company in the application state.
 * @param updateCompany - A mutation function to update the company data.
 * @param scrapeCompany - A mutation function to scrape the company data from the provided URL.
 * @param onNextStep - An optional callback function to be called after the company data has been updated.
 */
const scrapeWebsite = async (
  url: string,
  setAppLoader: (isLoading: boolean) => void,
  setActiveCompany: (company: Company) => void,
  updateCompany: any,
  scrapeCompany: any,
  onNextStep: () => void,
  currentStepIndex: number,
  activeCompany: Company,
) => {
  setAppLoader(true);
  scrapeCompany.mutate(
    {
      company_url: url,
    },
    {
      onSuccess: (data: Company) => {
        updateCompany.mutate(
          {
            ...data,
            onboarding_step: currentStepIndex + 1,
          },
          {
            onSuccess: (company: Company) => {
              setAppLoader(false);
              setActiveCompany(company);
              if (onNextStep) {
                onNextStep();
              }
            },
          },
        );
      },
      onError: () => {
        errorToaster(
          'Error happened during onboarding our team has been notified',
        );

        updateCompany.mutate(
          {
            ...activeCompany,
            onboarding_step: currentStepIndex + 1,
          },
          {
            onSuccess: (company: Company) => {
              setAppLoader(false);
              setActiveCompany(company);
              if (onNextStep) {
                onNextStep();
              }
            },
          },
        );
      },
    },
  );
};

export default scrapeWebsite;
