import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { SiQuickbooks } from 'react-icons/si';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import priorityIntegrationLogo from '@/public/assets/icons/priorityIntegrationLogo.jpeg';
import useRefetchIntegrations from './useRefetchIntegrations';
export interface Integration {
  api_key: string;
  company_id: number;
  type: string;
  created_by: number;
  update_interval: number;
  updated_at: string;
  service_id: string;
  client_secret: string;
  realm_id: number;
  id: number;
  integration_provider: string;
  latest_fetch_date: string;
  created_at: string;
  consumer_id: number;
  client_id: number;
  is_deleted: boolean;
}
const useIntegrations = () => {
  const {
    activeCompany,
    integrationsSyncing,
    setGetIntegrationIconFunction
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany,
    setIntegrationSyncDate: state.setIntegrationSyncDate,
    integrationsSyncing: state.integrationsSyncing,
    setGetIntegrationIconFunction: state.setGetIntegrationIconFunction
  })));
  const {
    setActiveIntegrations
  } = DataStore(useShallow(state => ({
    setActiveIntegrations: state.setActiveIntegrations
  })));
  const refetchIntegrationsAndRelevantData = useRefetchIntegrations(15);
  const {
    data,
    isLoading,
    error,
    refetch,
    isSuccess
  } = useQuery<Integration[], Error>({
    queryKey: [QUERYKEYS.INTEGRATIONS, activeCompany?.id],
    queryFn: () => ApiUtils.getIntegrations(activeCompany?.id),
    enabled: !!activeCompany?.id
    // after its fetched call setActiveIntegrations
  });
  useEffect(() => {
    // once we sync an integration we initally refecth to see if the data is updated
    if (Object.keys(integrationsSyncing)?.length > 0) {
      refetch();
    }
  }, [integrationsSyncing, refetch]);
  useEffect(() => {
    if (isSuccess && data) {
      if (data[0]?.integration_provider && data[0]?.integration_provider !== 'API_DECK') {
        setGetIntegrationIconFunction(() => <img src={priorityIntegrationLogo.src} width={20} alt="integration icon" data-text="Priority" />);
      } else {
        setGetIntegrationIconFunction(() => <SiQuickbooks color="rgb(44,160,28)" size={16} data-text="Quickbooks Online" />);
      }
      // Logic to execute after data is successfully fetched
      setActiveIntegrations(data);
    }
  }, [isSuccess, data, setActiveIntegrations]);

  // this is a useEffect to refetch the integrations that are syncing or are new
  useEffect(() => {
    let cleanups: (() => void)[] | undefined;
    if (data) {
      const integrationsFetchindData = data.filter(integration => !integration?.latest_fetch_date ||
      // if its a new integration
      integrationsSyncing?.[integration.id] // if its syncing
      );
      if (integrationsFetchindData) {
        cleanups = integrationsFetchindData?.map(integration => refetchIntegrationsAndRelevantData(integration));
      }
    }
    return () => {
      // call refetch sequentially until data is updated
      if (cleanups) {
        cleanups.forEach(cleanup => cleanup());
      }
    };
  }, [data, integrationsSyncing]);
  return {
    data,
    isLoading,
    error,
    refetch
  };
};
export default useIntegrations;