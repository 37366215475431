import React, { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import { generateCumulativeDateValuesFunc } from '@/miscellaneous/store/calulcationsStoreHelper';
import DataStore from '@/miscellaneous/store/DataStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import {
  createAdjustedUTCDate,
  generateMonthlyDates,
  getIsoString,
  parseUTCDateObject,
} from '@/utils/dateUtils';
import { mapBaseOutputIds } from '@/utils/hooks/Outputs/useOutputsHelper';

export const useRunwayCalculations = () => {
  const { runwayAndExpensesCurrentDate, endDate, setRunwayMonthlyValues } =
    useZustandStore(
      useShallow((state) => ({
        runwayAndExpensesCurrentDate: state.runwayAndExpensesCurrentDate,
        endDate: state.endDate,
        setRunwayMonthlyValues: state.setRunwayMonthlyValues,
      })),
    );

  const maxRunwayValue = 24;

  const { bothDataMap } = CalculationsStore(
    useShallow((state) => ({
      bothDataMap: state.bothDataMap,
    })),
  );

  const { branchOutputs } = DataStore(
    useShallow((state) => ({
      branchOutputs: state.branchOutputs,
    })),
  );

  const banksOutput = useMemo(
    () =>
      branchOutputs.find(
        (output) => output.base_output_id === mapBaseOutputIds.banks,
      ),
    [branchOutputs],
  );

  const cumulativeBankValues = useMemo(
    () =>
      generateCumulativeDateValuesFunc(
        bothDataMap[banksOutput?.id ?? 0]?.outputTotalValues ?? {},
      ),
    [bothDataMap, banksOutput?.id],
  );

  const getBurnRateForDate = (currentDate: Date) => {
    const incomeOutput = branchOutputs.find(
      (output) => output.base_output_id === mapBaseOutputIds.income,
    );
    const expenseOutput = branchOutputs.find(
      (output) => output.base_output_id === mapBaseOutputIds.expense,
    );
    const cogsOutput = branchOutputs.find(
      (output) => output.base_output_id === mapBaseOutputIds.cogs,
    );

    const threeMonthsAgo = createAdjustedUTCDate({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      day: currentDate.getDate(),
      adjustment: { value: -2, unit: 'months' },
    });

    const dates = generateMonthlyDates(threeMonthsAgo, currentDate);
    const currentDateString = getIsoString(currentDate);
    dates.push(currentDateString);

    const values = dates
      .map((date) => {
        let value = 0;
        [incomeOutput].forEach((output) => {
          const outputMap = bothDataMap[output?.id ?? 0];
          value -= outputMap?.outputTotalValues?.[date] ?? 0;
        });
        [expenseOutput, cogsOutput].forEach((output) => {
          const outputMap = bothDataMap[output?.id ?? 0];
          value += outputMap?.outputTotalValues?.[date] ?? 0;
        });
        return value;
      })
      .filter((val) => val !== 0);

    const averageBurnRate =
      values.length > 0 ? values.reduce((a, b) => a + b, 0) / values.length : 1;

    return averageBurnRate <= 0 ? 1 : averageBurnRate;
  };

  React.useEffect(() => {
    const start = runwayAndExpensesCurrentDate
      ? parseUTCDateObject(runwayAndExpensesCurrentDate)
      : new Date();
    const end = endDate ? parseUTCDateObject(endDate) : new Date();
    const dates = generateMonthlyDates(start, end);

    const runwayValues: Record<string, number> = {};

    dates.forEach((date) => {
      const currentDate = parseUTCDateObject(date);
      const burnRate = getBurnRateForDate(currentDate);
      const banksValue = cumulativeBankValues[date] ?? 0;

      let runwayValue = banksValue / burnRate;
      if (banksValue <= 0) runwayValue = 0;
      if (Number.isNaN(runwayValue)) runwayValue = 0;

      runwayValues[date] = Math.min(Math.ceil(runwayValue), maxRunwayValue);
    });

    setRunwayMonthlyValues(runwayValues);
  }, [
    runwayAndExpensesCurrentDate,
    endDate,
    bothDataMap,
    branchOutputs,
    cumulativeBankValues,
  ]);
};
