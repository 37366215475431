import 'react-widgets/styles.css';
import numeral from 'numeral';
import { useCallback, useEffect, useState } from 'react';
import Combobox from 'react-widgets/Combobox';
import styles from './CustomSelectInput.module.scss';
type Multipliers = {
  readonly K: number;
  readonly M: number;
};
interface CustomSelectInputProps {
  items: string[];
  sign?: string;
  selectValue?: string;
  onSaveValue?: (value: number) => void;
  dataTestId?: string;
}
const MULTIPLIERS: Multipliers = {
  K: 1000,
  M: 1000000
};

/**
 * Converts a string value with K/M suffix to a formatted number
 * Examples: "1K" -> "1,000", "1M" -> "1,000,000"
 */
const formatNumberValue = (value: string, sign: string): string => {
  if (!value) return numeral(0).format(`${sign}0,0`);
  const normalized = value.toString().trim().toUpperCase();
  const suffix = normalized.slice(-1);
  if (suffix in MULTIPLIERS) {
    const number = normalized.slice(0, -1);
    const numeralValue = numeral(number).value();
    if (numeralValue === null) return numeral(0).format(`${sign}0,0`);
    return numeral(numeralValue * MULTIPLIERS[(suffix as keyof Multipliers)]).format(`${sign}0,0`);
  }
  return numeral(value).format(`${sign}0,0`);
};

/**
 * CustomSelectInput - A enhanced select input component with number formatting
 *
 * Features:
 * - Supports K/M suffix conversion (1K = 1,000)
 * - Custom prefix sign support (e.g. "$")
 * - Automatic number formatting with thousands separator
 * - Controlled value management
 *
 * @example
 * ```tsx
 * <CustomSelectInput
 *   items={["1K", "2M", "500"]}
 *   sign="$"
 *   selectValue="1K"
 *   onSaveValue={(value) => console.log(value)}
 * />
 * ```
 */
const CustomSelectInput = ({
  items,
  sign = '$',
  selectValue,
  onSaveValue,
  dataTestId
}: CustomSelectInputProps) => {
  const [value, setValue] = useState<string>(formatNumberValue(selectValue?.toString() ?? '0', sign));
  useEffect(() => {
    setValue(formatNumberValue(selectValue?.toString() ?? '0', sign));
  }, [selectValue, sign]);
  const handleValueChange = useCallback((selectedValue: string) => {
    const formattedValue = formatNumberValue(selectedValue, sign);
    setValue(formattedValue);
    if (onSaveValue) {
      const numeralValue = (numeral(formattedValue).value() as number);
      onSaveValue(numeralValue);
    }
  }, [sign, onSaveValue]);
  return <Combobox hideCaret hideEmptyPopup value={value || `${sign}0`} data={items} containerClassName={styles.customSelectInput} placeholder="Select..." onSelect={handleValueChange} onChange={handleValueChange} data-testid={dataTestId} data-sentry-element="Combobox" data-sentry-component="CustomSelectInput" data-sentry-source-file="CustomSelectInput.tsx" />;
};
export default CustomSelectInput;