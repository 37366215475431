import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoOrganization } from 'react-icons/go';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';
import dynamicRoute from '@/miscellaneous/constant';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import type { Company } from '@/utils/hooks/company/useCompany';
import useCompany from '@/utils/hooks/company/useCompany';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { errorToaster } from '@/utils/toaster/toasters';
import { getSlug } from '../../../../miscellaneous/helper';
import DeletePopup from '../../DeletePopup/DeletePopup';
import LinkDropdown from '../LinkDropdown';

/**
 * Handles the creation of a new company.
 *
 * This function creates a new company with the following default data:
 * - name: 'New Company'
 * - description: 'New Company'
 * - slug: 'new-company'
 * - owner_id: the owner ID of the currently active company
 *
 * After creating the new company, the function updates the active company in the application state and the user's cookie.
 *
 * @returns The newly created company object.
 */
export const handleCreateCompany = async (createCompany: any, setActiveCompany: (company: Company) => void, activeCompany: Company, newCompanyDataAdditionalFields?: {
  [key: string]: any;
}) => {
  const newCompanyData = {
    name: 'New Company',
    description: 'New Company',
    slug: 'new-company',
    owner_id: activeCompany?.owner_id,
    ...newCompanyDataAdditionalFields
  };
  const company = await createCompany.mutateAsync(newCompanyData);
  setActiveCompany(company);
  return company;
};

/**
 * Renders a dropdown menu for managing the user's companies.
 *
 * The dropdown includes options to switch between the user's companies, create a new company, and delete the currently selected company.
 * The currently active company is displayed at the top of the dropdown, and the user can click on other companies to switch to them.
 * The dropdown also includes an option to create a new company, which will add it to the list of the user's companies.
 * If the user tries to delete the last remaining company, a warning message is displayed instead.
 */
const CompaniesDropDown = () => {
  const router = useRouter();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<any>(null);
  const {
    t: translate
  } = useTranslation(['company']);
  const {
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany
  })));
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  const {
    userCompanies,
    setActiveCompany
  } = useCompany();
  const {
    deleteCompany,
    createCompany,
    updateCompany
  } = useMutations();
  const {
    t
  } = useTranslation(['company']);

  /**
   * Renders a company logo image or a fallback organization icon.
   *
   * @param size - The desired size of the logo or icon in pixels.
   * @param className - An optional CSS class name to apply to the image or icon.
   * @returns A React element representing the company logo or fallback organization icon.
   */
  const renderCompanyLogo = (size: number, item: Company, className?: string) => {
    if (item?.logo_url) {
      return <img src={item?.logo_url} alt="Company Logo" className={className} width={size} height={size} />;
    }
    return <GoOrganization color={colors.black} size={size} className={className} data-sentry-element="GoOrganization" data-sentry-component="renderCompanyLogo" data-sentry-source-file="CompaniesDropDown.tsx" />;
  };

  /**
   * Generates an array of menu item objects for the user's companies to display in the dropdown.
   *
   * Loops through the user's companies and returns an object for each with the company name, slug, and link.
   * The link is built dynamically based on the company slug and routes.
   */
  const generateItems = () => {
    return userCompanies && userCompanies?.map((item: Company) => {
      const companySlug = item?.slug;
      const href = `/${companySlug}/main/${dynamicRoute.financials}`;
      return {
        label: item?.name,
        href,
        id: item.id,
        icon: renderCompanyLogo(16, item)
      };
    });
  };

  /**
   * Deletes the currently selected company from the user's list of companies.
   *
   * If there is only one company left, it will display an error message and not allow the deletion.
   * If the deleted company was the active company, it will set the active company to the first remaining company.
   * After a successful deletion, it will refetch the user's token data and update the cookie.
   */
  const handleDeleteCompany = async () => {
    if (!currentCompany) return;
    const {
      id
    } = currentCompany;

    // Check if there is only one company left
    if (userCompanies.length <= 1) {
      errorToaster(t('company:delete_error'));
      return;
    }

    // If the deleted company was the active company, set the active company to the first remaining company
    if (id === activeCompany.id) {
      const remainingCompanies = userCompanies.filter((company: any) => company.id !== id);
      setActiveCompany(remainingCompanies[0]);
    }

    // Delete the company
    await deleteCompany.mutateAsync({
      id
    }, {
      onSuccess: () => {
        toast.success(t('company:success_delete_alert'));
      }
    });
  };

  /**
   * Updates the name and slug of a company.
   *
   * @param id - The ID of the company to update.
   * @param newName - The new name for the company.
   * @returns The updated company object.
   */
  const handleUpdateCompany = async (id: number, newName?: string) => {
    if (!id || !newName ||
    // company name wasn`t change
    userCompanies.find((company: any) => company.id === id).name === newName) {
      return;
    }
    const newCompanyResult = await updateCompany.mutateAsync({
      id,
      slug: getSlug(newName),
      name: newName
    }, {
      onSettled: async (_: unknown, __: unknown, newCompany: Company) => {
        if (activeCompany && id === activeCompany.id) {
          setActiveCompany({
            id: newCompany.id,
            company_id: newCompany.company_id,
            name: newCompany.name,
            slug: newCompany.slug
          });
        }
      }
    });
    return newCompanyResult;
  };
  const validateCompanyName = (value: string): boolean => {
    if (value.length < 3) {
      toast.error(t('company:name_too_short'));
      return false;
    }
    return true;
  };

  /**
   * Handles the deletion of a company.
   *
   * @param id - The ID of the company to delete.
   * @returns void
   */
  const handleDelete = (id: number) => {
    setIsModalOpen(true);

    // Check if there is only one company left
    if (userCompanies.length <= 1) {
      errorToaster(t('company:delete_error'));
      return;
    }
    const company = userCompanies.find((c: any) => c.id === id);
    setCurrentCompany(company);
  };
  return <div className="relative flex items-center" data-sentry-component="CompaniesDropDown" data-sentry-source-file="CompaniesDropDown.tsx">
      {renderCompanyLogo(28, activeCompany, 'mr-4')}
      <LinkDropdown style={{
      fontWeight: '400'
    }} validInputChecker={validateCompanyName} checkedId={activeCompany?.id} color="black" onDelete={handleDelete} onEdit={handleUpdateCompany} onAddClick={() => {
      setAppLoader(true);
      handleCreateCompany(createCompany, setActiveCompany, activeCompany).finally(() => {
        setAppLoader(false);
      });
    }} items={generateItems()} header={activeCompany?.name || (router.query?.company as string)} onLinkClick={(item: any) => {
      setAppLoader(true);
      setActiveCompany(userCompanies.find((company: any) => company.id === item.id));
      router.push(item.href);
      setTimeout(() => {
        setAppLoader(false);
      }, 3000);
    }} text={translate('create_new_company')} direction="bottom left" contentStyle={{
      marginLeft: '-50px'
    }} data-sentry-element="LinkDropdown" data-sentry-source-file="CompaniesDropDown.tsx" />

      <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setCurrentData={setCurrentCompany} title={t('company:delete_company_alert')} sumbitTitle={t('company:delete_company')} closeModal={() => setIsModalOpen(false)} onDelete={() => {
      handleDeleteCompany();
      setIsModalOpen(false);
    }} isLastData={userCompanies.length <= 1} data-sentry-element="DeletePopup" data-sentry-source-file="CompaniesDropDown.tsx" />
    </div>;
};
export default CompaniesDropDown;