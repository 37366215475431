import dynamic from 'next/dynamic';
import { Component } from 'react';
import type { ChartTypes } from '@/utils/types/DashboardAndChartsTypes';
import styles from '../graphs.module.scss';
const Chart = dynamic(() => import('react-apexcharts'), {
  ssr: false
});
// remove this oldDashboard when BasicOldDashboard-old.tsx file is deleted
interface Props {
  oldDashboard?: Boolean;
  type: ChartTypes;
  options: object;
  series: {
    name: string;
    data: number[];
  }[] | {
    data: number[];
  }[] | number[];
  // eslint-disable-next-line react/no-unused-prop-types
  height?: number | string | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  width?: number | string | undefined;
}
// Define the interface for your component's state
interface ComponentState {
  options: object;
  series: {
    name: string;
    data: number[];
  }[] | {
    data: number[];
  }[] | number[];
  isLoaded: boolean;
}

/**
 * Props for the ChartComponent component.
 * @typedef {Object} ChartComponentProps
 * @property {'area' | 'line' | 'bar' | 'pie' | 'donut' | 'radialBar' | 'scatter' | 'bubble' | 'heatmap' | 'candlestick' | 'boxPlot' | 'radar' | 'polarArea' | 'rangeBar' | 'rangeArea' | 'treemap' | undefined} type - The type of chart to render.
 * @property {object} options - The configuration options for the chart.
 * @property {{ name: string; data: number[] }[] | { data: number[] }[] | number[]} series - The data series for the chart.
 */

/**
 * ChartComponent component.
 * This component renders a dynamic chart using ApexCharts.
 * @class
 */
class ChartComponent extends Component<Props, ComponentState> {
  /**
   * Constructor for ChartComponent.
   * @constructor
   * @param {ChartComponentProps} props - The props for the ChartComponent component.
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      options: (this.props as Props).options,
      series: (this.props as Props).series,
      isLoaded: false // this is a hack to fix the animation issue
    };
  }

  /**
   * Get derived state from props.
   * @static
   * @param {ChartComponentProps} nextProps - The next props for the component.
   * @param {ChartComponentProps} prevState - The previous state of the component.
   * @returns {ChartComponentProps | null} The updated state or null if no update is needed.
   * this function handles change in size of the chart component in the chart dynamically
   */
  static getDerivedStateFromProps(nextProps: Props) {
    return {
      options: nextProps.options,
      series: nextProps.series
    };
  }

  /**
   * Component did update.
   * @param {ChartComponentProps} prevProps - The previous props for the component.
   */

  componentDidUpdate(prevProps: Props) {
    if (prevProps.options !== this.props.options || prevProps.series !== this.props.series) {
      this.setState({
        options: this.props.options,
        series: this.props.series
      });
    }

    // this is a hack to fix the animation issue
    setTimeout(() => {
      this.setState({
        isLoaded: true
      });
    }, 100);
  }

  /**
   * Render the ChartComponent.
   * @returns {JSX.Element} The rendered JSX element.
   */

  render() {
    return <div className={styles.ChartHeightWidth} data-sentry-component="ChartComponent" data-sentry-source-file="ChartComponent.tsx">
        <Chart key={this.state.isLoaded ? null : Math.random()} // this is a hack to fix the animation issue
      height={this.props.height || '100%'} data-testid="mocked-chart" width={this.props.width ? this.props.width : (this.props as Props).type !== 'donut' ? '100%' : '300'} style={{
        padding: 0,
        margin: 0
      }} options={(this.state as ComponentState).options} series={(this.state as any).series} type={(this.props as Props).type} className={
      /**
       * Because the BasicOldDashboard-old.tsx is causing CSS issues with the dynamic dashboard.
       */
      !this.props.oldDashboard ? (this.props as Props).type !== 'donut' ? styles.customChart : styles.customDonut : ''} data-sentry-element="Chart" data-sentry-source-file="ChartComponent.tsx" />
      </div>;
  }
}
export default ChartComponent;