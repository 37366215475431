/* eslint-disable @typescript-eslint/no-shadow */
import classNames from 'classnames';
import { FileText, Split } from 'lucide-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Oval } from 'react-loader-spinner';
import { Tooltip } from 'react-tooltip';
import { useShallow } from 'zustand/react/shallow';
import { goToFormulaById } from '@/components/table/shared/cells/formulaCell/formulaCellUtils';
import { getModelNameById, ModelName } from '@/miscellaneous/store/CalculationsStore';
import type { CombinedEntry } from '@/miscellaneous/store/calulcationsStoreHelper';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import icon from '@/public/assets/images/new-logo.svg';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { formatDateToMonthAndYearAbbreviation, parseUTCDateObject } from '@/utils/dateUtils';
import { formatNumber } from '@/utils/helpers/formattingHelper';
import type { Output } from '@/utils/types/DashboardAndChartsTypes';
import styles from '../FinancialDrawerContent/financialDrawerContentStyles.module.scss';

/**
 * Defines the props for the header of the financial transactions list.
 *
 * @property {string} header - The title or header text for the transactions list.
 * @property {number | undefined | Output} totalValue - The total value of the transactions, which may be undefined or an Output.
 */
interface HeaderProps {
  header: string;
  totalValue: number | undefined | Output;
  onClick: () => void;
  isCollapsed: boolean;
}

/**
 * Renders a sub-header for the transactions list, displaying the month and year abbreviation, and the total value of the transactions for that date.
 *
 * @param header - The date for which the sub-header is being rendered.
 * @param totalValue - The total value of the transactions for the given date.
 * @returns The rendered sub-header component.
 */
const TransactionsSubHeader: React.FC<HeaderProps> = ({
  header,
  totalValue,
  onClick,
  isCollapsed
}) => {
  return <div className={styles.transactionsSubHeader} onClick={onClick} data-sentry-component="TransactionsSubHeader" data-sentry-source-file="Transactions.tsx">
      <div className=" flex items-center justify-between">
        <div className="flex items-center gap-1">
          {isCollapsed ? <IoMdArrowDropright size={18} /> : <IoMdArrowDropdown size={18} />}
          <h1>
            {header && formatDateToMonthAndYearAbbreviation(parseUTCDateObject(header))}
          </h1>
        </div>
        <div>
          <span>${formatNumber(totalValue) || 0}</span>
        </div>
      </div>
    </div>;
};

/**
 * Defines the props for a single transaction item in the financial transactions list.
 *
 * @property {string} date - The date of the transaction.
 * @property {Formula} formula - The financial formula associated with the transaction.
 * @property {number | undefined} value - The value of the transaction.
 */
interface TransactionItemProps {
  date: string;
  entry: CombinedEntry;
}

/**
 * Renders a single transaction item, displaying the date, formula name, and value.
 *
 * @param date - The date of the transaction.
 * @param formula - The financial formula associated with the transaction.
 * @param value - The value of the transaction.
 * @returns The rendered transaction item.
 */
const TransactionItem: React.FC<TransactionItemProps> = ({
  date,
  entry
}) => {
  const {
    activeBranchModels,
    getIntegrationIconFunction
  } = useZustandStore(useShallow((state: ZustandState) => {
    return {
      activeBranchModels: state.activeBranchModels,
      getIntegrationIconFunction: state.getIntegrationIconFunction
    };
  }));
  // Parses the date string to display only the date portion.
  const dataParser = entry?.date && entry?.date.slice(0, 10);
  const {
    t: translate
  } = useTranslation(['financialsDrawer']);

  // Determines if the entry is a formula entry.
  const isFormulaEntry = !!entry?.formula_id;

  // Gets the entry type based on the formula ID.
  const entryType = entry.formula_id ? getModelNameById(entry.formula_id) : ModelName.System;

  // Gets the model name based on the entry model ID.
  const getModelName = (modelId: number) => {
    return activeBranchModels.find(model => model.id === modelId)?.name;
  };

  /**
   * Renders a section with an icon and text for a transaction entry.
   *
   * @param text - The text to display in the section.
   * @returns The rendered entry details section.
   */
  const entryDetailsSection = (text: string) => {
    return <div className="mt-2 flex items-center gap-1" data-sentry-component="entryDetailsSection" data-sentry-source-file="Transactions.tsx">
        <Image src={icon} width={25} height={25} alt="logo" className="rounded-full" data-sentry-element="Image" data-sentry-source-file="Transactions.tsx" />
        <span>{text}</span>
      </div>;
  };
  const integrationIcon = getIntegrationIconFunction && getIntegrationIconFunction();
  const integrationText = integrationIcon?.props['data-text'] || 'QuickBooks';

  // Renders the metadata section of the transaction item, including the memo and origin.
  const transactionMetaData = !isFormulaEntry ? <div className={styles.transactionsItemMetaDataDetails}>
      <div className={styles.splitAndMemoEntrySection}>
        <div className={styles.memoEntrySection}>
          <FileText size={16} />
          <span>
            {entry.memo ? entry.memo : translate('financialsDrawer:no_memo')}
          </span>
        </div>
        {entry?.split_entry_id && <Split aria-label="split" size={16} />}
      </div>
      <div className={styles.originEntrySection}>
        {integrationIcon}
        <span>{integrationText}</span>
      </div>
    </div> : entryType === 'Model' ? entryDetailsSection(`${getModelName((entry.model_id as number))} Model`) : entryType === ModelName.Hiring ? entryDetailsSection('Hiring Plan Model') : entryDetailsSection('System Model');
  const router = useRouter();
  return <div className={classNames(styles.transactionsItem, {
    [(styles.hoveredTransactionItem as string)]: entryType === 'Model'
  })} key={entry.id} data-tooltip-id={entryType === 'Model' ? 'entry-tooltip' : undefined} data-tooltip-content="Go to Formula" onClick={() => goToFormulaById(entry.formula_id, router)} data-sentry-component="TransactionItem" data-sentry-source-file="Transactions.tsx">
      <div>
        <span>{dataParser || date}</span>
      </div>
      <div className="flex items-center justify-between">
        <h1 className={styles.transactionsItemName}>{entry.name}</h1>
        <p>${formatNumber(entry.amount)}</p>
      </div>
      {transactionMetaData}
    </div>;
};

/**
 * Renders a collapsible section for a set of financial transactions, including a header with the total value and the ability to expand/collapse the transaction items.
 *
 * @param date - The date for the transactions.
 * @param currentEntries - An array of the combined entries for the given date.
 * @param totalValue - The total value of all the transactions for the given date.
 * @returns The rendered collapsible transaction section.
 */
const CollapsibleTransactionSection = ({
  date,
  currentEntries,
  totalValue
}: {
  date: string | undefined;
  currentEntries: CombinedEntry[] | undefined;
  totalValue: number | undefined | Output;
}) => {
  const [isOpened, setIsOpened] = useState(true);
  return <>
      <TransactionsSubHeader header={(date as string)} totalValue={totalValue} onClick={() => setIsOpened(!isOpened)} isCollapsed={!isOpened} data-sentry-element="TransactionsSubHeader" data-sentry-source-file="Transactions.tsx" />
      <Collapse isOpened={isOpened} data-sentry-element="Collapse" data-sentry-source-file="Transactions.tsx">
        <div>
          {currentEntries?.map((entry: CombinedEntry) => {
          return entry.amount ? <React.Fragment key={`${entry.id}-${date}${entry.name}`}>
                <TransactionItem date={(date as string)} entry={entry} />
              </React.Fragment> : null;
        })}
        </div>
      </Collapse>
    </>;
};

/**
 * Defines the props for the `Transactions` component, which is responsible for rendering a list of financial transactions.
 *
 * @property {Formula[]} formulas - An array of financial formulas to be displayed.
 * @property {string[]} headers - An array of header strings for the transaction list.
 * @property {string | string[]} dates - A single date or an array of dates to be used for the transactions.
 * @property {(formula: Formula, date: string) => number | undefined} findFormulaValueFromEntries - A function that returns the value of a given formula for a specific date.
 * @property {(date: string) => number | undefined | Output} findTotalValueFromEntries - A function that returns the total value of all transactions for a specific date.
 */
interface TransactionsProps {
  entriesByDate: {
    [date: string]: CombinedEntry[];
  };
  transactionScrollContainer: React.RefObject<HTMLDivElement>;
}

/**
 * Renders a list of transactions, including a header and individual transaction items.
 *
 * @param formulas - An array of formula objects to display.
 * @param headers - An array of header strings for the transactions.
 * @param findFormulaValueFromEntries - A function to find the value of a formula for a given date from the branch entries.
 * @param findTotalValueFromEntries - A function to find the total value from the entries for the given formula and date.
 * @param dates - The dates to display the transactions for.
 * @returns The rendered transactions component.
 */
// Add these state variables at the start of Transactions component
const Transactions: React.FC<TransactionsProps> = ({
  entriesByDate,
  transactionScrollContainer
}) => {
  const [visibleDates, setVisibleDates] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const ITEMS_PER_PAGE = 5; // Number of date groups to load at once

  useEffect(() => {
    // Initialize with first batch of dates
    const dates = Object.keys(entriesByDate);
    setVisibleDates(dates.slice(0, ITEMS_PER_PAGE));
    setHasMore(dates.length > ITEMS_PER_PAGE);
  }, [entriesByDate]);
  const loadMoreDates = () => {
    const dates = Object.keys(entriesByDate);
    const currentLength = visibleDates.length;
    const nextBatch = dates.slice(currentLength, currentLength + ITEMS_PER_PAGE);
    setVisibleDates([...visibleDates, ...nextBatch]);
    setHasMore(currentLength + ITEMS_PER_PAGE < dates.length);
  };
  const generateTransactionsContent = () => {
    return <>
        <Tooltip id="entry-tooltip" place="left" data-sentry-element="Tooltip" data-sentry-source-file="Transactions.tsx" />
        {visibleDates.map((date: string, index: number) => {
        const currentEntries = entriesByDate[date];
        const totalValue = currentEntries?.reduce((acc, entry) => acc + entry.amount, 0);
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${date}-${index}`}>
              <CollapsibleTransactionSection date={date} currentEntries={currentEntries} totalValue={totalValue} />
            </React.Fragment>
        );
      })}
      </>;
  };
  return <div data-sentry-component="Transactions" data-sentry-source-file="Transactions.tsx">
      <InfiniteScroll dataLength={visibleDates?.length} next={loadMoreDates} hasMore={hasMore} loader={<div className="flex items-center justify-center py-3">
            <Oval visible height="20" width="20" color={colors.lightGrey} secondaryColor={colors.midBlue} strokeWidth={5} />
          </div>} scrollableTarget={(transactionScrollContainer?.current as React.ReactNode) ?? undefined} scrollThreshold={0.9} data-sentry-element="InfiniteScroll" data-sentry-source-file="Transactions.tsx">
        <div className={styles.transactions}>
          {generateTransactionsContent()}
        </div>
      </InfiniteScroll>
    </div>;
};
export default Transactions;