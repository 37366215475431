import classNames from 'classnames';
import { Blocks, File } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSparklesOutline } from 'react-icons/io5';
import { useShallow } from 'zustand/react/shallow';
import ShineBorder from '@/components/common/ShineBorder/ShineBorder';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import avatar from '@/public/assets/images/avatar.png';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import styles from './newCompanySetupStage.module.scss';
export enum CompanyPlanType {
  BlankPlan = 'blankPlan',
  FastPlan = 'fastPlan',
  PlanBuilder = 'planBuilder',
}
interface NewCompanySetupStageProps {
  data: {
    selectedPlan: CompanyPlanType;
  };
  onChange: (data: {
    selectedPlan: CompanyPlanType;
  }) => void;
}

/**
 * Component to render the new company setup stage.
 */
const NewCompanySetupStage: React.FC<NewCompanySetupStageProps> = ({
  data,
  onChange
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const [selectedPlan, setSelectedPlan] = useState(data.selectedPlan);
  useEffect(() => {
    setSelectedPlan(data.selectedPlan);
  }, [data.selectedPlan]);
  const {
    userMe
  } = useZustandStore(useShallow((state: ZustandState) => ({
    userMe: state.userMe
  })));
  const username = userMe?.first_name?.trim() || userMe?.full_name?.trim() || userMe?.email?.split('@')[0]?.trim() || userMe?.username || '';
  const usernameString = username ? `, ${username}!` : '!';
  /**
   * Generates a styled animation for the selected AI plan.
   * @param child - The content to render inside the animation.
   * @param selectedIndex - The current selected plan index.
   * @param activeIndex - The index of the active plan to animate.
   * @returns Rendered plan content with or without animation.
   */
  const generateAiPlanAnimation = (child: React.ReactNode, selected: CompanyPlanType, activePlan: CompanyPlanType) => {
    return selected === activePlan ? <ShineBorder borderRadius={9} borderWidth={4} duration={8} color={[(variables.midBlue as string), (variables.livelyPink as string), (variables.paleOrange as string)]} data-sentry-element="ShineBorder" data-sentry-component="generateAiPlanAnimation" data-sentry-source-file="NewCompanySetupStage.tsx">
        {child}
      </ShineBorder> : child;
  };

  /**
   * Configuration for rendering the company plans dynamically.
   */
  const planConfig = [{
    icon: <File size={24} />,
    title: translate('blank_plan'),
    description: translate('blank_plan_description'),
    badge: null,
    type: CompanyPlanType.BlankPlan
  }, {
    icon: <IoSparklesOutline size={24} />,
    title: translate('fast_plan'),
    description: translate('fast_plan_description'),
    badge: translate('early_access'),
    isHtml: true,
    type: CompanyPlanType.FastPlan
  }, {
    icon: <Blocks size={24} />,
    title: translate('plan_builder'),
    description: translate('plan_builder_description'),
    badge: null,
    isHtml: true,
    type: CompanyPlanType.PlanBuilder
  }];

  /**
   * Renders a single plan card.
   */
  const renderPlanCard = (plan: CompanyPlanType, {
    icon,
    title,
    description,
    badge,
    isHtml
  }: (typeof planConfig)[number]) => {
    const content = <div data-testid={(`${plan}-card` as string)} className={classNames(styles.companyPlan, {
      [(styles.selected as string)]: selectedPlan === plan
    })} onClick={() => onChange({
      selectedPlan: plan
    })}>
        {icon}
        <h1>{title}</h1>
        {isHtml ? <p dangerouslySetInnerHTML={{
        __html: description
      }} /> : <p>{description}</p>}
        {badge && <span className={styles.bottomBadge}>{badge}</span>}
      </div>;
    return plan === CompanyPlanType.FastPlan ? generateAiPlanAnimation(content, selectedPlan, plan) : content;
  };
  return <div className={styles.newCompanySetupStage} data-testid="new-company-setup-stage" data-sentry-component="NewCompanySetupStage" data-sentry-source-file="NewCompanySetupStage.tsx">
      <div className={styles.newCompanySetupStageContent}>
        {/* Welcome Message */}
        <div className={styles.welcomeMessage}>
          <img src={userMe?.image || avatar.src} alt="avatar" />
          <h1>{`${translate('welcome')}${usernameString}`}</h1>
        </div>
        {/* Company Plans */}
        <div className={styles.companyPlans}>
          {planConfig.map(config => renderPlanCard(config.type, config))}
        </div>
      </div>
    </div>;
};
export default NewCompanySetupStage;