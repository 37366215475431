import { MdError } from 'react-icons/md';
import type { ToastOptions } from 'react-toastify';
import { toast } from 'react-toastify';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
const errorToaster = (message: string, config: ToastOptions = {
  position: 'top-right'
}) => {
  toast.error(message, {
    ...config,
    progressStyle: {
      background: colors.midBlue3
    },
    icon: <MdError color={colors.midBlue3} size={22} />
  });
};
export { errorToaster };