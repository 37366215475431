import { useState } from 'react';

import useMutations from './mutations/useMutations';

/**
 * A custom React hook that provides text-to-speech functionality.
 *
 * The `useTextToSpeech` hook returns an object with two properties:
 * - `textToSpeech`: a function that takes a string of text and converts it to an audio file, which is then played using the browser's audio player.
 * - `isLoading`: a boolean that indicates whether the text-to-speech conversion is currently in progress.
 *
 * The hook uses the `useMutations` hook to fetch the audio response from an AI voice service, and then converts the base64-encoded audio data to a Blob and plays it using the `Audio` API.
 */
const useTextToSpeech = () => {
  const { getAiVoiceResponse } = useMutations();
  const [isLoading, setIsLoading] = useState(false);

  const textToSpeech = async (text: string) => {
    try {
      setIsLoading(true);
      const response = await getAiVoiceResponse.mutateAsync({
        freetext: text,
      });

      // Convert base64-encoded audio data to a Blob
      const audioData = atob(response.audio);
      const arrayBuffer = new ArrayBuffer(audioData.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < audioData.length; i += 1) {
        uint8Array[i] = audioData.charCodeAt(i);
      }

      // Create a Blob from the audio data
      const audioBlob = new Blob([uint8Array], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);

      // Play the audio using the browser's audio player
      const audio = new Audio(audioUrl);
      await audio.play();

      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching audio', error);
      setIsLoading(false);
    }
  };

  return { textToSpeech, isLoading };
};

export default useTextToSpeech;
