/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
import { CalendarDays, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Popup from 'reactjs-popup';
import { useDebouncedCallback } from 'use-debounce';
import { useShallow } from 'zustand/react/shallow';
import CustomSelectInput from '@/components/common/CustomSelectInput/CustomSelectInput';
import MonthYearPicker from '@/components/common/datePickers/MonthYearPicker';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { SelectStyle } from '@/styles/SelectComponentStyle/select';
import { getDateStringFormat, parseUTCDateObject } from '@/utils/dateUtils';
import useMutations from '@/utils/hooks/mutations/useMutations';
import styles from '../../OnboardingPopup.module.scss';
import { areStringsEqualIgnoreWhitespaceAndCase } from '../../onBoardingPopupBodyFunctions';
import type { CompanyGoals, CompanyState, DetailsConfirmation, SelectData } from '../../onboardingPopupTypes';
import { QuestionBox } from '../CompanyDetailsStage/CompanyDetailsStage';
type SelectedOption = {
  value: string;
  label: string;
};

/**
 * Renders the Details Confirmation stage of the Company Onboarding Popup.
 *
 * @param onChange - A function to update the details confirmation data.
 * @param data - The current details confirmation data.
 * @param selectData - An array of select data items, containing information about the select fields.
 * @param resetNextStepData - A function to reset the data for the next step.
 * @returns The rendered Details Confirmation stage.
 */
const DetailsConfirmationStage = ({
  onChange,
  data,
  selectData,
  type,
  companyState,
  activeStepIndex,
  companyDescriptionRef
}: {
  onChange: (data: DetailsConfirmation | CompanyGoals) => void;
  data: DetailsConfirmation & CompanyGoals;
  selectData: SelectData[];
  type: string;
  companyState: CompanyState;
  activeStepIndex: number;
  companyDescriptionRef: any;
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dataState, setDataState] = useState<DetailsConfirmation & CompanyGoals>(data);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const {
    extractFieldsCompanyDescriptions,
    extractGoalsCompanyDescriptions
  } = useMutations();
  const {
    activeCompany
  } = useZustandStore(useShallow(state => ({
    activeCompany: state.activeCompany
  })));
  const isDetailsUpdated = useRef<boolean>(false);

  /**
   * A debounced callback function that extracts goals from the company description text.
   *
   * @param {string} freetext - The text company description text to extract goals from.
   * @returns {void}
   */
  const debouncedExtractGoalsCompanyDescriptions = useDebouncedCallback((freetext: string) => {
    setIsFetching(true);
    extractGoalsCompanyDescriptions?.mutate({
      freetext
    }, {
      onSuccess: (response: any) => {
        const responseData = response;

        // Create new state object with the response data
        const newData = {
          ...dataState,
          howManyEmployees: responseData?.EMPLOYEES || undefined,
          revenueAYearFromNow: responseData?.REVENUE || 0,
          whenYouRaiseFunding: responseData?.WHEN || getDateStringFormat(parseUTCDateObject()),
          howMuchFunding: responseData?.FUNDING || 0
        };

        // Update both local and parent state
        setDataState(newData);
        onChange(newData);
        setIsFetching(false);
      },
      onError: (error: any) => {
        setIsFetching(false);
        console.log('Error extracting goals from company description: ', error);
      }
    });
  }, 300); // Adjust the debounce delay as needed

  /**
   * A debounced callback function that extracts fields from the company description text.
   *
   * @param {string} freetext - The company description text to extract fields from.
   * @returns {void}
   */
  const debouncedExtractFieldsCompanyDescriptions = useDebouncedCallback((freetext: string) => {
    setIsFetching(true);
    extractFieldsCompanyDescriptions?.mutate({
      freetext
    }, {
      onSuccess: (response: any) => {
        const responseData = response;

        // Create new state object with the response data
        const newData = {
          ...dataState,
          teamLocation: responseData?.LOCATION ?? 'United States of America (USA)',
          companySectors: responseData?.SECTORS && responseData?.SECTORS.length ? responseData?.SECTORS.slice(0, 3) : ['Other'],
          howMuchFunding: responseData?.FUNDING || 0,
          companyStage: responseData?.STAGE || undefined
        };

        // Update both local and parent state
        setDataState(newData);
        onChange(newData);
        setIsFetching(false);
      },
      onError: (error: any) => {
        setIsFetching(false);
        console.log('Error extracting fields from company description: ', error);
      }
    });
  }, 300); // Adjust the debounce delay as needed

  useEffect(() => {
    setDataState(data);
  }, [data]);

  /**
   * Handles the change event for a select field in the Details Confirmation stage.
   *
   * @param {SelectedOption | SelectedOption[]} selectedOption - The selected option(s) from the select field.
   * @param {boolean} isMultiSelect - Indicates whether the select field allows multiple selections.
   * @param {number} [selectLimit] - The maximum number of options that can be selected.
   * @returns {void}
   */
  const handleChange = (selectedOption: SelectedOption | SelectedOption[], isMultiSelect: boolean, selectLimit?: number, key?: string) => {
    if (Array.isArray(selectedOption) && selectedOption.length === 0) {
      onChange({
        ...dataState,
        [(key as string)]: []
      });
      setDataState({
        ...dataState,
        [(key as string)]: []
      });
      return;
    } // if no option is selected, return

    setDisabled(false);

    // if the selected option is not multi select and the selected option is an array, disable the button
    if (isMultiSelect && Array.isArray(selectedOption) && selectedOption.length > 0) {
      // If the selected option is multi select and the select limit is reached, disable the button
      const newData: DetailsConfirmation = {
        ...dataState,
        [((selectedOption[0] as SelectedOption).value.split('-')[0] as string)]: selectedOption.map(option => option.label)
      };
      onChange(newData);
      setDataState((newData as DetailsConfirmation & CompanyGoals));
    } else {
      // If the selected option is not multi select and the selected option is an array, set the value of the selected option to the first element's label.
      const newData: DetailsConfirmation = {
        ...dataState,
        [((selectedOption as SelectedOption).value.split('-')[0] as string)]: (selectedOption as SelectedOption).label
      };
      onChange(newData);
      setDataState((newData as DetailsConfirmation & CompanyGoals));
    }

    // if the selected option is multi select and the select limit is reached, disable the button
    if (isMultiSelect && selectLimit && Array.isArray(selectedOption) && selectedOption.length >= selectLimit) {
      setDisabled(true);
    }
  };
  const checkIfDataIsChanged = () => {
    const isDescriptionChanged = !areStringsEqualIgnoreWhitespaceAndCase(companyDescriptionRef.current, activeCompany?.description || '');

    // Check if the onboarding step is incomplete
    const isOnboardingStepIncomplete = (activeCompany?.onboarding_step as number) <= activeStepIndex;
    // Check if the data is empty
    const isDataEmpty = !Object.values(dataState).some(
    // @ts-ignore
    value => value?.length > 0);
    // Check if the details have already been updated
    const isDetailsAlreadyUpdated = isDetailsUpdated.current;
    return isDescriptionChanged || isOnboardingStepIncomplete && isDataEmpty || isDetailsAlreadyUpdated;
  };
  useEffect(() => {
    if (checkIfDataIsChanged()) {
      if (type === 'Details Confirmation') {
        debouncedExtractFieldsCompanyDescriptions(companyState['Company Details'].text);
        companyDescriptionRef.current = activeCompany.description;
        isDetailsUpdated.current = true;
      }
      if (type === 'Company Goals') {
        debouncedExtractGoalsCompanyDescriptions(companyState['Company Details'].text);
        isDetailsUpdated.current = false;
      }
    }
  }, [type, companyState['Company Details'].text]);

  /**
   * Calculates the default value for a select field based on the data and select data item.
   *
   * @param data - The details confirmation data object.
   * @param item - The select data item containing information about the select field.
   * @returns The default value for the select field, or null if no default value is available.
   */
  const calculateDefaultValue = (data: DetailsConfirmation, item: SelectData) => {
    return Array.isArray(data[(item.key as keyof DetailsConfirmation)]) ? (data[(item.key as keyof DetailsConfirmation)] as string[]).map(option => ({
      label: option,
      value: `${item.key}-${option}`
    })) : data[(item.key as keyof DetailsConfirmation)] ? {
      label: data[(item.key as keyof DetailsConfirmation)],
      value: `${item.key}-${data[(item.key as keyof DetailsConfirmation)]}`
    } : null;
  };

  /**
   * Calculates the CSS class name for a calendar tile based on the date and view.
   *
   * @param param0 - An object containing the date and view information.
   * @param param0.date - The date of the calendar tile.
   * @param param0.view - The current view of the calendar (e.g. 'year').
   * @returns The CSS class name for the calendar tile, or an empty string if the tile should not be highlighted.
   */
  const tileClassName = ({
    date: newDate,
    view
  }: {
    date: Date;
    view: string;
  }) => {
    if (view === 'year') {
      if (parseUTCDateObject(newDate).getTime() === parseUTCDateObject((dataState as CompanyGoals).whenYouRaiseFunding).getTime()) {
        return 'active-month';
      }
    }
  };

  /**
   * Renders a calendar popup trigger that displays the month and year of the `whenYouRaiseFunding` date from the `CompanyGoals` data.
   *
   * @returns A React element that renders the calendar popup trigger.
   */
  const calendarPopupTrigger = () => {
    const calendarDate = (dataState as CompanyGoals).whenYouRaiseFunding;
    const clearDate = () => {
      onChange({
        ...dataState,
        whenYouRaiseFunding: null
      });
      setDataState({
        ...dataState,
        whenYouRaiseFunding: null
      });
    };
    return <div className={styles.calendarField} data-testid="calendar-select" data-sentry-component="calendarPopupTrigger" data-sentry-source-file="DetailsConfirmationStage.tsx">
        <div className="flex items-center gap-1">
          <CalendarDays size={16} data-sentry-element="CalendarDays" data-sentry-source-file="DetailsConfirmationStage.tsx" />
          {calendarDate ? getDateStringFormat(parseUTCDateObject(calendarDate), 'MMMM YYYY') : 'Select a date'}
        </div>
        {calendarDate && <X size={16} onClick={clearDate} />}
      </div>;
  };

  /**
   * Renders a select field component with the specified configuration.
   *
   * @param item - The `SelectData` object containing the configuration for the select field.
   * @returns A React element that renders the select field.
   */
  const renderSelectField = (item: SelectData) => {
    const defaultValue = calculateDefaultValue(dataState, item) ?? undefined;
    const generateKey = defaultValue ? defaultValue.toString() : Math.random();
    return <Select data-testid={`${item.key}-select`} aria-label={item.question} className={styles.selectField} defaultValue={defaultValue ?? undefined} value={defaultValue ?? undefined} options={item.data?.map(option => ({
      label: option,
      value: `${item.key}-${option}`
    }))} key={generateKey} isSearchable placeholder={translate('select')} onChange={selectedOption => handleChange((selectedOption as SelectedOption), item.isMultiSelect, item.selectLimit, item.key)} isMulti={item.isMultiSelect} styles={SelectStyle} isOptionDisabled={() => !!(item.isMultiSelect && disabled)} data-sentry-element="Select" data-sentry-component="renderSelectField" data-sentry-source-file="DetailsConfirmationStage.tsx" />;
  };

  /**
   * Renders a calendar popup trigger that displays a month and year picker for the `whenYouRaiseFunding` date from the `CompanyGoals` data.
   *
   * @param item - The `SelectData` object containing the configuration for the date picker.
   * @returns A React element that renders the calendar popup trigger and the month and year picker.
   */
  const renderDatePicker = (item: SelectData) => <Popup trigger={calendarPopupTrigger} contentStyle={{
    marginTop: '5px'
  }} position="bottom left" arrow={false} data-sentry-element="Popup" data-sentry-component="renderDatePicker" data-sentry-source-file="DetailsConfirmationStage.tsx">
      <MonthYearPicker value={
    // @ts-ignore
    dataState[item.key] ?
    // @ts-ignore
    parseUTCDateObject(dataState[item.key]) : parseUTCDateObject()} onChange={(date: Date) => {
      onChange({
        ...dataState,
        [item.key]: getDateStringFormat(date)
      });
      setDataState({
        ...dataState,
        [item.key]: getDateStringFormat(date)
      });
    }} className="calendar-design" tileClassName={tileClassName} data-sentry-element="MonthYearPicker" data-sentry-source-file="DetailsConfirmationStage.tsx" />
    </Popup>;
  const renderCurrencyField = (item: SelectData) => {
    const defaultValue = calculateDefaultValue(dataState, item) ?? undefined;
    return <CustomSelectInput dataTestId={`${item.key}-select`} items={item.data?.map(option => (option as string)) ?? []} selectValue={defaultValue && (defaultValue as SelectedOption)?.label} onSaveValue={(value: number) => {
      onChange({
        ...dataState,
        [item.key]: value
      });
      setDataState({
        ...dataState,
        [item.key]: value
      });
    }} data-sentry-element="CustomSelectInput" data-sentry-component="renderCurrencyField" data-sentry-source-file="DetailsConfirmationStage.tsx" />;
  };
  const renderNumberField = (item: SelectData) => {
    const defaultValue = calculateDefaultValue(dataState, item) ?? undefined;
    return <CustomSelectInput items={item.data?.map(option => (option as string)) ?? []} selectValue={defaultValue && (defaultValue as SelectedOption)?.label} sign="" onSaveValue={(value: number) => {
      onChange({
        ...dataState,
        [item.key]: value
      });
      setDataState({
        ...dataState,
        [item.key]: value
      });
    }} data-sentry-element="CustomSelectInput" data-sentry-component="renderNumberField" data-sentry-source-file="DetailsConfirmationStage.tsx" />;
  };
  return <div className={styles.detailsConfirmationStage} data-testid="details-confirmation" data-sentry-component="DetailsConfirmationStage" data-sentry-source-file="DetailsConfirmationStage.tsx">
      {selectData.map(item => {
      // Check if the field is checked
      const isChecked = (() => {
        // @ts-ignore
        const value = dataState[item.key];
        if (item.key === 'whenYouRaiseFunding') {
          return value === null || !!value;
        }
        const isNumericField = ['howMuchFunding', 'revenueAYearFromNow'].includes(item.key);
        if (isNumericField) {
          return value === 0 || value === '0' || !!value;
        }
        return !!value;
      })();
      return <div key={item.question}>
            <div className={styles.inputFieldContainer}>
              <QuestionBox question={item.question} isChecked={isChecked} fetching={isFetching} />
              {item.type === 'date' ? renderDatePicker(item) : item.type === 'currency' ? renderCurrencyField(item) : item.type === 'number' ? renderNumberField(item) : renderSelectField(item)}
            </div>
          </div>;
    })}
    </div>;
};
export default DetailsConfirmationStage;