/* eslint-disable import/no-cycle */

import { getSignForGraph } from '@/components/Graphs/graphHelper';
import { dateFormatter, parseUTCDateObject } from '@/utils/dateUtils';
import type {
  CommonConfigOptions,
  SeriesItem,
} from '@/utils/types/DashboardAndChartsTypes';

import styles from '../../../components/Graphs/graphs.module.scss';
import { areaChartOptions } from './areaConfig';
import { barComboChartOptions } from './barComboConfig';
import { barChartOptions } from './barConfig';
import { donutChartOptions } from './donutConfig';
import { lineChartOptions } from './lineConfig';
import { stackedComboConfigOptions } from './stackedComboConfig';
import { stackedChartOptions } from './stackedConfig';

function getLabel(options: {
  dateStr: number | string;
  timePeriodType: string | undefined;
  isToolTip: boolean;
  dates?: (string | undefined)[];
}) {
  const { dateStr, timePeriodType, isToolTip, dates } = options;

  let date;
  let month;
  let year;
  let quarter;
  let formattedData;
  switch (timePeriodType) {
    case 'is_quarterly':
      date = parseUTCDateObject(dateStr);
      month = date.getMonth() + 1;
      year = isToolTip
        ? date.getFullYear()
        : date.getFullYear().toString().slice(-2);

      if (month >= 1 && month <= 3) {
        quarter = 'Q1';
      } else if (month >= 4 && month <= 6) {
        quarter = 'Q2';
      } else if (month >= 7 && month <= 9) {
        quarter = 'Q3';
      } else {
        quarter = 'Q4';
      }

      formattedData = `${quarter} ${year}`;

      break;
    case 'is_monthly':
      date = parseUTCDateObject(dateStr);
      formattedData = isToolTip
        ? date.toLocaleString('en-US', {
            month: 'short',
            year: 'numeric',
          })
        : date.toLocaleString('default', { month: 'short', year: '2-digit' });
      break;
    case 'is_annualy':
      formattedData = isToolTip
        ? dates?.[(dateStr as number) - 1]?.split('-')?.[0]
        : (dateStr as string)?.split('-')?.[0];
      break;
    default:
      // Handle other cases if needed
      break;
  }
  return { formattedData };
}

function getSpecificOptionsForChart(
  minValue: number,
  maxValue: number,
  dynamicSeries: any,
  chartType:
    | 'line'
    | 'bar'
    | 'stacked'
    | 'donut'
    | 'barCombo'
    | 'StackedCombo'
    | 'GroupedStackedCombo'
    | 'area',
  dataGroup: any,
) {
  const signForGraph = getSignForGraph(dataGroup);

  switch (chartType) {
    case 'line':
      return lineChartOptions({ minValue, maxValue }, signForGraph);
    case 'bar':
      return barChartOptions({ minValue, maxValue }, signForGraph);
    case 'barCombo':
      return barComboChartOptions(
        { minValue, maxValue, dynamicSeries },
        signForGraph,
      );
    case 'stacked':
      return stackedChartOptions({ dynamicSeries }, signForGraph);
    case 'StackedCombo':
      return stackedComboConfigOptions(
        { minValue, maxValue, dynamicSeries },
        signForGraph,
      );
    case 'GroupedStackedCombo':
      return stackedComboConfigOptions(
        { minValue, maxValue, dynamicSeries },
        signForGraph,
      );
    case 'area':
      return areaChartOptions({ minValue, maxValue }, signForGraph);
    default:
      return {};
  }
}

function getCommonOptions(
  graphColors: string[] | undefined,
  dates: (string | undefined)[],
  timePeriodType: string | undefined,
) {
  return {
    fill: { colors: graphColors },
    colors: graphColors,
    legend: {
      fontFamily: 'Figtree',
      fontSize: '14px',
      fontWeight: '600',
      itemMargin: {
        horizontal: 20,
        vertical: 0,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 2,
      },
      showForSingleSeries: true,
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter(value: number, data: any) {
          const timeFormat = () => {
            return timePeriodType === 'is_annualy' ||
              timePeriodType === 'is_quarterly'
              ? getLabel({
                  dateStr: value,
                  timePeriodType,
                  isToolTip: false,
                }).formattedData
              : dateFormatter(parseUTCDateObject(value), true);
          };

          return Number.isNaN(+value)
            ? timeFormat()
            : data?.w?.globals?.categoryLabels?.[value - 1];
        },
        style: {
          fontFamily: styles.fontFamily,
          fontSize: '14px',
          fontWeight: '400',
        },
      },
      theme: 'light',
      style: {
        fontFamily: styles.fontFamily,
        fontSize: '14px',
        fontWeight: '400',
        backgroundColor: 'white',
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      tickAmount: 3,
      // tickPlacement: 'on',
      style: {
        fontFamily: 'Figtree',
        fontSize: '14px',
        fontWeight: '400',
      },
      type: 'category',
      categories: dates,
      labels: {
        minHeight: 45,
        formatter(value: any) {
          const { formattedData } = getLabel({
            dateStr: value,
            timePeriodType,
            isToolTip: false,
          });

          return formattedData;
        },
        rotate: 0,
      },
    },
  };
}

/**
 * Generates configuration for various types of charts (line, bar, stacked).
 *
 * @function
 *
 * @param {'line' | 'bar' | 'stacked' | 'donut' | 'barCombo'} chartType - Type of chart (line, bar, stacked).
 * @param {CommonConfigOptions} options - Options for configuring the chart.
 * @returns {{ option: object, series: any[] }} - Configuration for the specified chart type.
 */
export const getGraphConfig = (
  chartType:
    | 'line'
    | 'bar'
    | 'stacked'
    | 'donut'
    | 'barCombo'
    | 'StackedCombo'
    | 'GroupedStackedCombo'
    | 'area',
  options: CommonConfigOptions,
  dataGroup: any,
) => {
  const { dates, dynamicSeries, graphColors, labels, timePeriodType } = options;

  // Add sorting for donut chart series
  let sortedSeries = dynamicSeries;
  if (chartType === 'donut' && Array.isArray(dynamicSeries)) {
    sortedSeries = [...dynamicSeries].sort(
      (a, b) => (a as number) - (b as number),
    ) as SeriesItem[];
  }

  let maxValue;
  let minValue;
  if (
    chartType !== 'donut' &&
    chartType !== 'stacked' &&
    chartType !== 'StackedCombo' &&
    chartType !== 'GroupedStackedCombo' &&
    Array.isArray(dynamicSeries)
  ) {
    // calculate max value from all series in dynamicSeries
    maxValue =
      dynamicSeries && dynamicSeries?.length > 0
        ? (dynamicSeries as any[])?.reduce((acc: any, curr: any) => {
            return Math.max(acc, Math.max(...curr.data));
          }, 0)
        : 10;

    // calculate min value from all series in dynamicSeries
    minValue =
      dynamicSeries && dynamicSeries?.length > 0
        ? (dynamicSeries as any[])?.reduce((acc: any, curr: any) => {
            return Math.min(acc, Math.min(...curr.data));
          }, 0)
        : 10;
  }
  if (
    (chartType === 'stacked' ||
      chartType === 'StackedCombo' ||
      chartType === 'GroupedStackedCombo') &&
    Array.isArray(dynamicSeries) &&
    dynamicSeries.length > 0
  ) {
    // Iterate over the first dataset's data array
    for (
      let i = 0;
      i < (dynamicSeries as SeriesItem[])?.[0]?.data?.length!;
      i += 1
    ) {
      const sum =
        dynamicSeries && dynamicSeries?.length > 0
          ? (dynamicSeries as any[])?.reduce(
              (acc, dataset) => acc + (dataset as any).data[i],
              0,
            )
          : 0; // Sum corresponding elements from all dynamicSeries
      minValue = Math.min(minValue, sum as number, 0); // so if min value is greater than 0 it will still display it
      maxValue = Math.max(maxValue, sum as number);
    }
  }

  const commonOptions = getCommonOptions(graphColors, dates, timePeriodType);

  if (maxValue <= minValue) {
    // handle edge case where all values are 0 so the graphs will still render
    maxValue = minValue + 10;
  }
  let specificOptionsForChart: any = {};
  if (chartType === 'donut') {
    specificOptionsForChart = donutChartOptions({
      labels,
      graphColors,
      commonOptions,
      // @ts-ignore
      dynamicSeries,
    });
  } else
    specificOptionsForChart = getSpecificOptionsForChart(
      minValue,
      maxValue,
      dynamicSeries,
      chartType,
      dataGroup,
    );

  return {
    option: {
      chart: {
        toolbar: {
          show: false,
        },
        offsetX: 0,
      },
      ...commonOptions,
      ...(specificOptionsForChart || {}), // Apply specific options based on chart type
    },
    series:
      dynamicSeries &&
      // @ts-ignore
      dynamicSeries?.filter((ds: number) => ds !== 0).length > 0
        ? sortedSeries
        : [1],
  };
};
