const dynamicRoute = {
  root: '/',
  login: '/login',
  signup: '/signup',
  financials: 'financials',
  hiringPlan: 'hiringplan',
  resetPassword: '/resetpassword',
  hiringSettings: '/hiringsettings',
  setting: '/settings',
  profile: '/settings/profile',
  organizationMember: '/settings/organization-members',
  billing: '/settings/organization-billing',
  projectBilling: '/settings/project-billing',
  planUsage: '/settings/plan-usage',
  templates: '/settings/templates',
  integrations: '/settings/integrations',
  organizationSetting: '/settings/organization-settings',
  models: 'models',
  general: 'general',
  assumptions: 'assumptions',
  accessError: '/access-error',
  defaultFinancialReport: 'financial-report',
  unAuthenticatedRoutes: [
    '/login',
    '/signup-hidden1',
    '/resetpassword',
    '/login-test',
    '/logout',
    '/access-error',
  ],
  integration: 'integration',
};
export default dynamicRoute;
