import type { User } from '@auth0/auth0-react';
import router from 'next/router';

import useZustandStore from './miscellaneous/store/zustand_store';
import mixpanel from './mixpanelConfig';
import { parseUTCDateObject } from './utils/dateUtils';

export const MIXPANEL_EVENTS = {
  ONBOARDING_STARTED: 'Onboarding Started',
  NEW_COMPANY_STARTED: 'NewCompany Started',
  PLAN_SELECTED: 'Plan Selected',
  GENERATE_CLICKED: 'Generate Clicked',
  FINISHED: 'Finished',
  CLOSED_POPUP: 'Closed Popup',
  LOGOUT_CLICKED: 'Logout Clicked',
  USER_LANDED_ON_PLATFORM: 'User Landed on the platform',
} as const;

export const MIXPANEL_KEYS = {
  ONBOARDING: 'Onboarding',
  NEW_COMPANY: 'NewCompany',
} as const;

export const identifyUserForMixpanel = (user: User) => {
  mixpanel.identify(user?.email);
  mixpanel.people.set({
    $userId: user?.sub,
    $email: user?.email,
    $name: user?.name,
    $last_login: parseUTCDateObject(),
  });
};

export const mixpanelTrackEventWrapper = (
  mixPanelEventString: string,
  context: Record<string, any> = {},
) => {
  const page = router?.pathname?.split('/')?.[3];
  const { activeBranch, activeCompany } = useZustandStore.getState();

  const companyDetails = {
    companyId: activeCompany?.id,
    branchId: activeBranch?.id,
  };

  mixpanel.track(mixPanelEventString, { ...context, page, companyDetails });
};
