import { apiEndpoints } from '@/utils/apis/apis';
import type {
  CreateModelsProps,
  LoginParmsProps,
  RegisterParmsProps,
} from '@/utils/types/types';

import { BulkDeleteType, Employee } from '@/utils/types/HiringTypes';
import axios, { CancelToken } from 'axios';
import { configureAxios } from './Index';

import { variables } from '@/styles/variables/constant';
import { GetPromptTypeProps } from '@/utils/types/AITypes';
import { getCookie } from 'cookies-next';
import {
  ApiCall,
  AxiosInstanceMethods,
  AxiosMethod,
  determineAxiosCallParams,
} from './APIUtilsHelper';

const genericApiCall = async ({
  method,
  endpoint,
  data,
  params,
  id,
  //@ts-ignore
  context,
  companyId,
  cancelToken,
}: ApiCall & { context?: any; companyId?: number; branch?: any }) => {
  /**
   * We can remove this whole axios instance handeling here and we can use the token here and just define axiosInstance = configureAxios(authToken, activeCompany?.id);
   * btw now that i look on this isent it
   *   const token = useAccessToken(); (check login-test.tsx)
   * also i can see now we are creating an axios instance for every call instead of having 1 instance and just passing data to it...
   */
  // const access_token = useAccessToken();
  // const url = id ? `${endpoint}${id}` : endpoint;
  // console.log("🚀 ~ genericApiCall access_token:", access_token)
  // console.log("🚀 ~ genericApiCall url:", url)

  // const axiosMethod = method.toLowerCase() as AxiosMethod;
  // const axiosInstance = configureAxios({
  //   access_token,
  // }, companyId);

  const authToken = getCookie(variables.lucid_user)
    ? JSON.parse(getCookie(variables.lucid_user) as string)
    : {};

  // Retrieve the active company details from cookies.
  const activeCompanyID = companyId
    ? companyId
    : getCookie(variables.lucid_user_active_company)
      ? JSON.parse(getCookie(variables.lucid_user_active_company) as string).id
      : null;

  const url = id ? `${endpoint}${id}` : endpoint;
  const axiosMethod = method.toLowerCase() as AxiosMethod;
  const axiosInstance = configureAxios(authToken, activeCompanyID);

  const call = axiosInstance[axiosMethod] as AxiosInstanceMethods[AxiosMethod];

  const callParams = determineAxiosCallParams({
    axiosMethod,
    url,
    data,
    params,
    cancelToken,
  });

  const response = await call(...(callParams as [string, any, any?])).catch(
    (error: any) => {
      if (axios.isCancel(error)) {
        // handle axios cancel request
        console.log('Request canceled', error.message);
      } else {
        throw error;
      }
    },
  );

  return response?.data;
};

// Define individual calls using the new structure
const ApiUtils = {
  aiExplainer: (params: any) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_EXPLAINER,
      data: params,
    });
  },

  getApideckVaultToken: (companyId: number) => {
    return genericApiCall({
      method: 'POST',
      endpoint:
        apiEndpoints.GET_APIDECK_VAULT_TOKEN + `?company_id=${companyId}`,
    });
  },

  getApideckConnectors: (companyId: number) => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.API_DECK_CONNECTORS,
      companyId,
    });
  },

  authLogin: (params: LoginParmsProps) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.LOGIN,
      data: params,
    }),
  signUp: (params: RegisterParmsProps) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.SIGN_UP,
      data: params,
    }),
  newToken: () =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.NEW_TOKEN,
    }),
  getFormulas: (params: number) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_FORMULAS,
      id: `?model_id=${params}`,
    }),
  getAllFormulas: (companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_FORMULAS,
      companyId,
      context,
    }),

  // Company endpoints
  getAllCompanies: (context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_ALL_COMPANIES,
      context,
    }),
  createCompany: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.COMPANY_CREATE,
      data: params,
    }),
  updateCompany: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.COMPANY_UPDATE,
      data: params,
      id: `/${params?.id}`,
    }),
  deleteCompany: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.COMPANY_DELETE,
      id: `/${params?.id}`,
    }),
  /////////////////////////////
  getBranchByCompanyId: (companyId: number, context?: any) => {
    if (!companyId) {
      return [];
    }

    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.BRANCH_FILTER,
      context,
      companyId,
    });
  },
  getGraphsByCompanyId: (params: number, companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GRAPHS,
      id: `?dashboard_id=${params}`,
      companyId,
      context,
    }),
  getAllOutputs: (companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.OUTPUTS,
      companyId,
      context,
    }),
  googleAuth: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.GOOGLE_AUTH,
      data: params,
    }),
  getUserMe: () =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.USERME,
    }),
  createBranch: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.BRANCH_CREATE,
      data: params,
    }),
  updateBranch: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.BRANCH_UPDATE,
      data: params,
      id: `/${params.id}`,
    }),
  cloneBranch: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.BRANCH_CLONE,
      data: params.body,
      id: `/${params.id}`,
    }),
  deleteBranch: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.BRANCH_DELETE,
      id: `/${params.id}`,
    }),
  createModel: (params: CreateModelsProps) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.MODELS_CREATE,
      data: params,
    }),
  updateModel: (params: CreateModelsProps) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.MODELS_UPDATE,
      data: params,
      id: `/${params.id}`,
    }),

  updateBulkModels: (params: CreateModelsProps[]) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.MODELS_UPDATE_BULK,
      data: params,
    });
  },
  deleteModel: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.MODELS_DELETE,
      id: `/${params.id}`,
    }),
  getDashboard: (params: number, companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.DASHBOARD_FILTER,
      id: `?model_id=${params}`,
      companyId,
      context,
    }),
  getModelByBranchId: (params: number, companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.MODELS_FILTER,
      id: `?branch_id=${params}`,
      companyId,
      context,
    }),

  getModelByCompany: (companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.MODELS_FILTER,
      companyId,
      context,
    }),

  /**
   * Fetches all templates.
   * @returns {Promise} - Resolves with an array of template data if successful, rejects with an error if there's an issue.
   */
  getAllTemplates: async (): Promise<any> =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_ALL_TEMPLATES,
    }),
  /**
   * Fetches all active templates.
   * @returns {Promise} - Resolves with an array of active template data if successful, rejects with an error if there's an issue.
   */
  getAllActiveTemplates: async (branchId: number): Promise<any> =>
    genericApiCall({
      method: 'GET',
      endpoint: `${apiEndpoints.GET_ACTIVE_TEMPLATES}/?branch_id=${branchId}`,
    }),

  applyTemplate: async (params: any) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.APPLY_TEMPLATE,
      data: params,
    });
  },

  createTemplate: async (params: any) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_TEMPLATE,
      data: params,
    });
  },

  getCustomeTemplates: async (companyId: number) => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_CUSTOM_TEMPLATES,
      companyId,
    });
  },

  /**
   * Removes a template from the system.
   * @param {string} id - The ID of the template to be removed.
   * @returns {Promise} - Resolves with a success message if successful, rejects with an error if there's an issue.
   */
  removeTemplate: async (data: { id: string }): Promise<any> =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.REMOVE_TEMPLATE(data.id),
    }),

  createFormula: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.FORMULA_CREATE,
      data: params,
    }),

  updateFormula: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.FORMULA_UPDATE,
      data: params,
      id: `/${params?.id}`,
    }),

  updateBulkFormula: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.FORMULA_UPDATE_BULK,
      data: params,
    }),

  deleteFormula: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.FORMULA_DELETE,
      id: `/${params.id}`,
    }),

  /**
   * Creates a new role for a user.
   * @param {string} email - User's email address.
   * @param {string} id - Company ID.
   * @returns {Promise} - Resolves with role data if successful, rejects with an error if there's an issue.
   */
  createRole: async (data: { email: any; id: any }): Promise<any> => {
    const { email, id } = data;
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_ROLE,
      data: {
        email,
        companyId_id: id,
        role: 'Viewer',
      },
    });
  },

  /**
   * Get all employees
   * @param {string} id - Company ID.
   * @returns {Promise} - Resolves with employee data if successful, rejects with an error if there's an issue.
   */
  getAllEmployees: async (companyId: number, context?: any): Promise<any> => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_ALL_EMPLOYEES,
      companyId,
      context,
    });
  },

  getEmployeeColumns: async (companyId: number): Promise<any> => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.EMPLOYEE_COLUMNS,
      companyId,
    });
  },

  deleteEmployeeColumn: async (params: any): Promise<any> => {
    return genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.DELETE_EMPLOYEE_COLUMNS,
      id: `/${params?.id}`,
    });
  },

  createEmployeeColumn: async (params: any): Promise<any> => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_EMPLOYEE_COLUMNS,
      data: params,
    });
  },

  updateEmployeeColumn: async (params: any): Promise<any> => {
    return genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.UPDATE_EMPLOYEE_COLUMNS,
      data: params,
      id: `/${params?.id}`,
    });
  },
  createGraph: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_GRAPH,
      data: params,
    }),
  updateGraph: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.UPDATE_GRAPH,
      data: params,
      id: `/${params?.options?.[0]?.graph_id}`,
    }),
  deleteGraph: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.DELETE_GRAPH,
      id: `/${params?.id}`,
    }),

  getHiringPlans: (params: any, companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.HIRING_PLAN_FILTER,
      id: `?branch_id=${params}`,
      context,
      companyId,
    }),
  getReports: (params: any, companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.REPORT_FILTER,
      id: `?branch_id=${params}`,
      context,
      companyId,
    }),

  updateReport: (params: any) => {
    return genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.REPORT_UPDATE,
      data: params,
      id: `/${params?.id}`,
    });
  },
  updateReportItem: (params: any) => {
    return genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.REPORT_ITEMS_UPDATE,
      data: params,
      id: `/${params?.id}`,
    });
  },
  createReportItem: (params: any) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.REPORT_ITEMS_CREATE,
      data: params,
    });
  },
  createReport: (params: any) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.REPORT_CREATE,
      data: params,
    });
  },

  deleteReport: (params: any) => {
    return genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.REPORT_DELETE,
      id: `/${params?.id}`,
    });
  },

  deleteReportItem: (params: any) => {
    return genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.REPORT_ITEMS_DELETE,
      id: `/${params?.id}`,
    });
  },
  createHiringPlan: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.HIRING_PLAN_CREATE,
      data: params,
    }),
  updateHiringPlan: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.HIRING_PLAN_UPDATE,
      data: params,
      id: `/${params?.id}`,
    }),
  deleteHiringPlan: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.HIRING_PLAN_DELETE,
      id: `/${params?.id}`,
    }),

  getBranchHiringSettings: (companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.BRANCH_HIRING_SETTINGS,
      context,
      companyId,
    }),

  createBranchHiringSettings: (params: any) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.BRANCH_HIRING_SETTINGS_CREATE,
      data: params,
    });
  },

  updateBranchHiringSettings: (params: any) => {
    return genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.BRANCH_HIRING_SETTINGS_UPDATE,
      data: params,
      id: `/${params?.id}`,
    });
  },

  getEmployeeRoles: (companyId: number, context?: any) =>
    genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_EMPLOYEE_ROLES_BY_COMPANY,
      context,
      companyId,
    }),
  getAllEntries: async (companyId: number, context?: any): Promise<any> => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.ENTRY_FILTER,
      companyId,
      context,
    });
  },
  getLiveEntries: async (companyId: number, context?: any): Promise<any> => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.LIVE_ENTRY_FILTER,
      companyId,
      context,
    });
  },

  createDahboard: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.DASHBOARD_CREATE,
      data: params,
    }),

  // Employee endoints
  createEmployee: (params: any) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.EMPLOYEE_CREATE,
      data: params,
    }),

  updateEmployee: (params: any) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.EMPLOYEE_UPDATE,
      data: params,
      id: `/${params?.id}`,
    }),

  deleteEmployee: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.EMPLOYEE_DELETE,
      id: `/${params?.id}`,
    }),
  createBulkEmployee: (params: Employee[]) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.EMPLOYEE_CREATE_BULK,
      data: params,
    }),
  createBulkGraph: (params: Employee[]) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_GRAPH_BULK,
      data: params,
    }),

  updateBulkEmployee: (params: Employee[]) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.EMPLOYEE_UPDATE_BULK,
      data: params,
    }),
  deleteBulkEmployee: (
    params: BulkDeleteType, // only id is required
  ) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.EMPLOYEE_DELETE_BULK,
      data: params,
    }),

  // AI Utils
  AIHiringPlanGenerate: (params: any, cancelToken?: CancelToken) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_UTILS_HIRING_PLAN_GENERATE,
      data: params,
      cancelToken,
    }),
  AIHiringPlanExpand: (params: any, cancelToken?: CancelToken) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_UTILS_HIRING_PLAN_EXPAND,
      data: params,
      cancelToken,
    }),
  AIHiringPlanDecrease: (params: any, cancelToken?: CancelToken) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_UTILS_HIRING_PLAN_DECREASE,
      data: params,
      cancelToken,
    }),
  AIHiringPlanModify: (params: any, cancelToken?: CancelToken) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_UTILS_HIRING_PLAN_MODIFY,
      data: params,
      cancelToken,
    }),
  AIgetPromptType: (params: GetPromptTypeProps, cancelToken?: CancelToken) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_UTILS_GET_PROMPT_TYPE,
      data: params,
      cancelToken,
    }),
  createOutput: (params: GetPromptTypeProps, cancelToken?: CancelToken) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.OUTPUTS_CREATE,
      data: params,
      cancelToken,
    }),
  updateOutput: (params: { id: string }) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.OUTPUTS_UPDATE,
      data: params,
      id: `/${params?.id}`,
    }),

  deleteOutput: (params: any) =>
    genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.OUTPUTS_DELETE,
      id: `/${params?.id}`,
    }),

  updateUserDates: (params: { id: string }) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.UPDATE_USER_DATES,
      data: params,
    }),
  updateIntegration: (
    params: any, // Something is wrong with the key here. When updating integration it requiers refetch, and deletes the data from the query cache instead of updating it
  ) =>
    genericApiCall({
      method: 'PUT',
      endpoint: apiEndpoints.UPDATE_INTEGRATION,
      data: params,
      id: `/${params?.id}`,
    }),
  getIntegrations: (companyId: number) => {
    return genericApiCall({
      method: 'GET',
      endpoint: apiEndpoints.GET_INTEGRATIONS,
      companyId,
    });
  },
  createIntegration: (
    params: any, // Something is wrong with the key here. When updating integration it requiers refetch, and deletes the data from the query cache instead of updating it
  ) =>
    genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.CREATE_INTEGRATION,
      data: params,
    }),
  removeIntegration: (params: { id: number }) => {
    return genericApiCall({
      method: 'DELETE',
      endpoint: apiEndpoints.REMOVE_INTEGRATION,
      id: `/${params.id}`,
    });
  },
  syncIntegration: (params: { id: number }) => {
    // Something is wrong with the key here. When updating integration it requiers refetch, and deletes the data from the query cache instead of updating it
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.SYNC_INTEGRATION,
      id: `/${params.id}`,
    });
  },
  validateQuestionsAndAnswers: (params: {
    freetext: string;
    questions: string[];
  }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.VALIDATE_QUESTIONS_AND_ANSWERS,
      data: params,
    });
  },

  aiExpandText: (params: { freetext: string; questions: string[] }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_EXPAND_TEXT,
      data: params,
    });
  },

  templateAssigner: (params: {}) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.TEMPLATE_ASSIGNER,
      data: params,
    });
  },

  finishOnboarding: async (params: { template_id_list: number[] }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.FINISH_ONBOARDING,
      data: params,
    });
  },

  scrapeCompany: async (params: { company_url: string }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.SCRAPE_COMPANY,
      data: params,
    });
  },

  extractFieldsCompanyDescriptions: async (params: { freetext: string }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.EXTRACT_FIELDS_COMPANY_DESCRIPTIONS,
      data: params,
    });
  },

  extractGoalsCompanyDescriptions: async (params: { freetext: string }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.EXTRACT_GOALS_COMPANY_DESCRIPTIONS,
      data: params,
    });
  },

  predictCompanyProducts: async (params: {}) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.PREDICT_COMPANY_PRODUCTS,
      data: params,
    });
  },

  fastOnboarding: async (params: { company_url: string }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.FAST_ONBOARDING,
      data: params,
    });
  },

  sendErrorReport: async (params: { error_message: string }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.SEND_ERROR_REPORT,
      data: params,
    });
  },

  getAiVoiceResponse: async (params: { freetext: string }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.AI_VOICE_RESPONSE,
      data: params,
    });
  },

  getPromptSummary: async (params: {
    user_input: string;
    response: string;
    prompt_type: string;
    prompt_operation: string;
  }) => {
    return genericApiCall({
      method: 'POST',
      endpoint: apiEndpoints.GET_PROMPT_SUMMARY,
      data: params,
    });
  },
};

export default ApiUtils;
