/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
import { getCookie } from 'cookies-next';
import { SiQuickbooks } from 'react-icons/si';
import { create } from 'zustand';
import type { PersistOptions } from 'zustand/middleware';
import { persist } from 'zustand/middleware';
import type { AIItemProps } from '@/components/AIComponents/AIItemView';
import type { FinancialsDrawerState } from '@/components/financialReports/FinancialDrawer/FinancialDrawerContent/FinancialDrawerContent';
import type { IReport } from '@/components/Settings/HiringSettings/Reports';
import type { IRole } from '@/components/Settings/HiringSettings/Roles';
import type { ITemplate } from '@/components/Settings/Template/components';
import { variables } from '@/styles/variables/constant';
import { adjustUTCDate, createAdjustedUTCDate, generateMonthlyDates, getDateStringFormat, parseUTCDateObject } from '@/utils/dateUtils';
import type { Branch } from '@/utils/hooks/branches/useBranches';
import type { Company } from '@/utils/hooks/company/useCompany';
import type { Dashboard } from '@/utils/hooks/dashboard/DashboardHookHelper';
import { systemFormulas } from '@/utils/hooks/formulas/SystemFormulas';
import type { Models } from '@/utils/hooks/models/useModels';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { Report, ReportItem } from '@/utils/hooks/reports/reportTypes';
import type { EmployeeColumn } from '@/utils/hooks/settings/useEmployeeColumns';
import type { UserJson } from '@/utils/hooks/user_manager/useGetUserMe';
import type { Prompt } from '@/utils/types/AITypes';
import type { Graph } from '@/utils/types/DashboardAndChartsTypes';
import type { Formula } from '@/utils/types/formulaTypes';
import type { Employee, HiringPlan } from '@/utils/types/HiringTypes';
import CalculationsStore from './CalculationsStore';
import DataStore from './DataStore';
import AddGraphPopupStore from './graph_store';
// Define interfaces for your state and actions
export interface ZustandStoreDataState {
  user: Record<string, any>;
  userMe: UserJson | null;
  role: string;
  roles: IRole[];
  roleChoices: string[];
  newReports: IReport[];
  fields: EmployeeColumn[];
  monthlyDates: string[];
  fullTimelineMonthlyDates: string[];
  activeModelDates: string[];
  timelineStartDate: string;
  timelineEndDate: string;
  startDate: string | number | Date;
  endDate: string | number | Date;
  currentDate: Date | string;
  hiringDonutDateState: Date;
  isMenuOpen: boolean;
  activeCompany: Company;
  activeBranch: Branch;
  activeModel: Models;
  prompts: Prompt[];
  newOutputs: Output[];
  activeHiringPlan: any | null;
  branchFormulas: Formula[];
  newAddedTemplate: ITemplate | null;
  branchEmployees: Employee[];
  isSidebarDrawerOpen: boolean;
  sidebarDrawerContent: FinancialsDrawerState | null;
  activeBranchModels: Models[];
  activeModelFormulas: Formula[];
  branchHiringPlans: HiringPlan[];
  activeDashboard: Dashboard;
  activeGraphs: Graph[];
  activeReport: Report;
  branchReports: Report[];
  branchDashboards: Dashboard[];
  branchReportItems: ReportItem[];
  activeReportItems: ReportItem[];
  formulaIDToFormulaMap: Record<number, Formula>;
  isLoggedIn: any;
  isTableDisabled: boolean;
  branchGraphs: Graph[];
  query: string;
  outputIDToBaseOutputAncestorID: Record<number, number>;
  rowIDToScrollTo: string | null;
  logoutApp: () => void;
  integrationsSyncing: Record<number, string>; // used to sync integrations. if an integration id is in here its syncing. maps integration id to last sync date
  runwayAndExpensesCurrentDate: Date;
  // this should be a component
  getIntegrationIconFunction: any;
  runwayMonthlyValues: Record<string, number>;
}
export interface ZustandStoreActionsState {
  resetStore: () => void;
  setLoggedIn: (e: any) => void;
  setMonthlyDates: (e: string[]) => void;
  setFullTimelineMonthlyDates: (e: string[]) => void;
  setNewAddedTemplate: (e: ITemplate | null) => void;
  setBranchEmployees: (e: Employee[]) => void;
  setTableDisabled: (e: boolean) => void;
  setNewOutputs: (e: Output[]) => void;
  setBranchReportItems: (e: ReportItem[]) => void;
  setIsSidebarDrawerOpen: (e: boolean) => void;
  setSidebarDrawerContent: (e: FinancialsDrawerState) => void;
  setActiveModelFormulas: (e: Formula[]) => void;
  setActiveCompany: (e: Company) => void;
  setActiveBranch: (e: Branch) => void;
  setActiveModel: (e: Models) => void;
  setActiveHiringPlan: (e: HiringPlan) => void;
  setBranchHiringPlans: (e: HiringPlan[]) => void;
  setActiveDashboard: (e: Dashboard) => void;
  addPrompt: (prompt: Prompt | AIItemProps) => void;
  removePrompt: (id: number) => void;
  setActiveGraphs: (e: Graph[]) => void;
  setBranchFormulas: (formulas: Formula[]) => void;
  addBranchFormula: (formula: Formula) => void;
  resetPrompts: () => void;
  setTimelineStartDate: (e: string) => void;
  setTimelineEndDate: (e: string) => void;
  setStartDate: (e: string | number | Date) => void;
  setCurrentDate: (e: Date | string) => void;
  setHiringDonutDateState: (e: Date) => void;
  setEndDate: (e: string | number | Date) => void;
  setFields: (e: EmployeeColumn[]) => void;
  setNewReports: (e: IReport[]) => void;
  setRoles: (e: IRole[]) => void;
  setActiveBranchModels: (e: Models[]) => void;
  handleRoleChoices: (e: string[]) => void;
  handleIsMenuOpen: (e: boolean) => void;
  handleUserInformation: (e: Record<string, any>) => void;
  handleRole: (e: string) => void;
  setUserMe: (e: UserJson) => void;
  setActiveReport: (e: Report) => void;
  setBranchReports: (e: Report[]) => void;
  setActiveReportItems: (e: ReportItem[]) => void;
  setBranchDashboards: (e: Dashboard[]) => void;
  setBranchGraphs: (e: Graph[]) => void;
  setQuery: (e: string) => void;
  setOutputIDToBaseOutputAncestorID: (e: Record<number, number>) => void;
  setRowIDToScrollTo: (e: string | null) => void;
  setLogoutFunction: (e: () => void) => void;
  setIntegrationSyncDate: (integrationId: number, date: string) => void;
  setRunwayAndExpensesCurrentDate: (e: Date) => void;
  setGetIntegrationIconFunction: (e: any) => void;
  setRunwayMonthlyValues: (e: Record<string, number>) => void;
}
const defaultState: ZustandStoreDataState = {
  user: {},
  userMe: null,
  role: '',
  roles: [],
  roleChoices: [],
  newReports: [],
  fields: [],
  rowIDToScrollTo: null,
  monthlyDates: ([] as string[]),
  fullTimelineMonthlyDates: ([] as string[]),
  timelineStartDate: getDateStringFormat(createAdjustedUTCDate({
    adjustment: {
      value: -1,
      unit: 'years'
    },
    month: 0
  })),
  timelineEndDate: getDateStringFormat(createAdjustedUTCDate({
    adjustment: {
      value: 5,
      unit: 'years'
    },
    month: 0
  })),
  startDate: createAdjustedUTCDate({
    adjustment: {
      value: -6,
      unit: 'months'
    }
  }).getTime(),
  endDate: createAdjustedUTCDate({
    adjustment: {
      value: 18,
      unit: 'months'
    }
  }).getTime(),
  currentDate: parseUTCDateObject(),
  hiringDonutDateState: createAdjustedUTCDate({
    adjustment: {
      value: 3,
      unit: 'months'
    }
  }),
  runwayAndExpensesCurrentDate: adjustUTCDate(parseUTCDateObject(), 1),
  isMenuOpen: false,
  activeCompany: ({} as Company),
  activeBranch: ({} as Branch),
  activeModel: ({} as Models),
  prompts: ([] as Prompt[]),
  newOutputs: [],
  activeHiringPlan: null,
  branchFormulas: ([] as Formula[]),
  newAddedTemplate: null,
  branchEmployees: [],
  isSidebarDrawerOpen: false,
  sidebarDrawerContent: null,
  activeBranchModels: [],
  activeModelFormulas: [],
  branchHiringPlans: [],
  branchDashboards: [],
  activeDashboard: ({} as Dashboard),
  activeGraphs: [],
  activeReport: ({} as Report),
  branchReports: [],
  branchReportItems: [],
  activeReportItems: [],
  isLoggedIn: !!getCookie(variables.lucid_user),
  isTableDisabled: false,
  branchGraphs: [],
  query: '',
  activeModelDates: [],
  formulaIDToFormulaMap: {},
  outputIDToBaseOutputAncestorID: {},
  logoutApp: () => {},
  integrationsSyncing: {},
  getIntegrationIconFunction: () => <SiQuickbooks color="rgb(44,160,28)" size={16} data-text="Quickbooks Online" />,
  runwayMonthlyValues: {}
};
export type ZustandState = ZustandStoreDataState & ZustandStoreActionsState;

// Define the type for the persisted state
type PersistOptionsType = PersistOptions<ZustandState> & {
  partialize: (state: ZustandState) => Partial<ZustandState>;
};

// Define the Zustand store with types
const useZustandStore = create(persist<ZustandState>(set => ({
  ...defaultState,
  resetStore: () => {
    CalculationsStore.getState().resetStore();
    AddGraphPopupStore.getState().resetStore();
    DataStore.getState().resetStore();
    set(defaultState);
  },
  setLoggedIn: e => {
    set({
      isLoggedIn: e
    });
  },
  setMonthlyDates: e => {
    set({
      monthlyDates: e
    });
  },
  setFullTimelineMonthlyDates: e => {
    set({
      fullTimelineMonthlyDates: e
    });
  },
  setNewAddedTemplate: (e: ITemplate | null) => {
    set({
      newAddedTemplate: e
    });
  },
  setBranchEmployees: (e: Employee[]) => {
    set({
      branchEmployees: e
    });
  },
  setTableDisabled: e => {
    set({
      isTableDisabled: e
    });
  },
  setNewOutputs: (e: Output[]) => {
    set({
      newOutputs: e
    });
  },
  setBranchReportItems: (e: ReportItem[]) => {
    set({
      branchReportItems: e
    });
  },
  setIsSidebarDrawerOpen: e => {
    set({
      isSidebarDrawerOpen: e
    });
  },
  setSidebarDrawerContent: (e: FinancialsDrawerState) => {
    set({
      sidebarDrawerContent: e
    });
  },
  setActiveModelFormulas: (e: Formula[]) => {
    set({
      activeModelFormulas: e
    });
  },
  setActiveCompany: (e: Company) => {
    set({
      activeCompany: e
    });
  },
  setActiveBranch: e => {
    set({
      activeBranch: e
    });
  },
  setActiveModel: (e: Models) => {
    const start = e?.start_date ? parseUTCDateObject(e?.start_date) : parseUTCDateObject();
    start.setDate(1);
    const end = e?.end_date ? parseUTCDateObject(e?.end_date) : parseUTCDateObject();
    end.setDate(1);
    if (start.getMonth() === end.getMonth()) end.setMonth(end.getMonth() + 1);
    set({
      activeModel: e,
      activeModelDates: generateMonthlyDates(start, end)
    });
  },
  setActiveHiringPlan: (e: HiringPlan) => {
    set({
      activeHiringPlan: e
    });
  },
  setBranchHiringPlans: (e: HiringPlan[]) => {
    set({
      branchHiringPlans: e
    });
  },
  setActiveDashboard: e => {
    set({
      activeDashboard: e
    });
  },
  addPrompt: prompt => {
    set(state => ({
      prompts: [...state.prompts, prompt]
    }));
  },
  removePrompt: id => {
    set(state => ({
      prompts: (state.prompts as Prompt[]).filter(prompt => prompt.id !== id)
    }));
  },
  setActiveGraphs: (e: Graph[]) => {
    set({
      activeGraphs: e
    });
  },
  setBranchFormulas: formulas => {
    const formulaIDToFormulaMap: Record<number, Formula> = {};
    formulas.forEach(formula => {
      formulaIDToFormulaMap[formula.id] = formula;
    });
    set({
      branchFormulas: formulas.concat(systemFormulas),
      formulaIDToFormulaMap
    });
  },
  addBranchFormula: (formula: any) => {
    set(state => ({
      branchFormulas: [...state.branchFormulas, formula]
    }));
  },
  resetPrompts: () => {
    set({
      prompts: []
    });
  },
  setTimelineStartDate: e => {
    set({
      timelineStartDate: e
    });
  },
  setTimelineEndDate: e => {
    set({
      timelineEndDate: e
    });
  },
  setStartDate: e => {
    set({
      startDate: e
    });
  },
  setCurrentDate: (e: Date | string) => {
    set({
      currentDate: e
    });
  },
  setHiringDonutDateState: e => {
    set({
      hiringDonutDateState: e
    });
  },
  setEndDate: e => {
    set({
      endDate: e
    });
  },
  setFields: e => {
    set({
      fields: e
    });
  },
  setNewReports: (e: IReport[]) => {
    set({
      newReports: e
    });
  },
  setRoles: (e: IRole[]) => {
    set({
      roles: e
    });
  },
  setActiveBranchModels: e => {
    set({
      activeBranchModels: e
    });
  },
  handleRoleChoices: (e: string[]) => {
    set({
      roleChoices: e
    });
  },
  handleIsMenuOpen: e => {
    if (e !== useZustandStore.getState().isMenuOpen) {
      set({
        isMenuOpen: e
      });
    }
  },
  handleUserInformation: (e: Record<string, any>) => {
    set({
      user: e
    });
  },
  handleRole: e => {
    set({
      role: e
    });
  },
  setUserMe: (e: UserJson) => {
    set({
      userMe: e
    });
  },
  setActiveReport: (e: Report) => {
    set({
      activeReport: e
    });
  },
  setBranchReports: (e: Report[]) => {
    set({
      branchReports: e
    });
  },
  setActiveReportItems: (e: ReportItem[]) => {
    set({
      activeReportItems: e
    });
  },
  setBranchDashboards: (e: Dashboard[]) => {
    set({
      branchDashboards: e
    });
  },
  setBranchGraphs: (e: Graph[]) => {
    set({
      branchGraphs: e
    });
  },
  setQuery: (e: string) => {
    set({
      query: e
    });
  },
  setRowIDToScrollTo: (e: string | null) => {
    set({
      rowIDToScrollTo: e
    });
  },
  setOutputIDToBaseOutputAncestorID: (e: Record<number, number>) => {
    set({
      outputIDToBaseOutputAncestorID: e
    });
  },
  setIntegrationSyncDate(integrationId: number, date: string) {
    if (date === '') {
      set(state => {
        const newIntegrationsSyncing = {
          ...state.integrationsSyncing
        };
        // if integrationId is in the map delete it
        if (newIntegrationsSyncing?.[integrationId]) {
          delete newIntegrationsSyncing[integrationId];
        }
        return {
          integrationsSyncing: newIntegrationsSyncing
        };
      });
    } else {
      set(state => ({
        integrationsSyncing: {
          ...state.integrationsSyncing,
          [integrationId]: date
        }
      }));
    }
  },
  setLogoutFunction: (e: () => void) => {
    set({
      logoutApp: e
    });
  },
  setRunwayAndExpensesCurrentDate: (e: Date) => {
    set({
      runwayAndExpensesCurrentDate: e
    });
  },
  setGetIntegrationIconFunction: (e: any) => {
    set({
      getIntegrationIconFunction: e
    });
  },
  setRunwayMonthlyValues: (e: Record<string, number>) => {
    set({
      runwayMonthlyValues: e
    });
  }
}), (({
  name: 'zustand-storage',
  getStorage: () => sessionStorage,
  partialize: (state: ZustandState) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    const {
      prompts,
      ...persistedState
    } = state;
    return persistedState;
  }
} as unknown) as PersistOptionsType)));
export default useZustandStore;