import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';

import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import queryClient from '@/utils/queryClient';

import type { Integration } from './useIntegrations';

/**
 * A custom React hook that periodically refetches integration data and relevant data (e.g. employees, live entries, outputs) based on the active company.
 *
 * @param {number} second - The interval in seconds at which to refetch the integration and relevant data.
 * @returns {(integrationObject: Integration) => void} - A function that, when called with an `Integration` object, will start the refetch interval.
 */
const useRefetchIntegrations = (second: number) => {
  const { activeCompany, setIntegrationSyncDate, integrationsSyncing } =
    useZustandStore(
      useShallow((state: ZustandState) => ({
        activeCompany: state.activeCompany,
        setIntegrationSyncDate: state.setIntegrationSyncDate,
        integrationsSyncing: state.integrationsSyncing,
      })),
    );
  const { t: translate } = useTranslation('integrations');

  // Create ref to store interval ID
  let refetchInterval: NodeJS.Timeout;

  const refetchIntegrationsAndRelevantData = (
    integrationObject: Integration,
  ) => {
    // Clear any existing interval before setting new one
    if (refetchInterval) {
      clearInterval(refetchInterval);
    }
    const previousFetchDate = integrationsSyncing?.[integrationObject.id]; // the date of the last sync. if its create integration this will be null

    refetchInterval = setInterval(() => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERYKEYS.INTEGRATIONS, activeCompany?.id],
        })
        .then(() => {
          const relevantIntegration = queryClient
            .getQueryData<Integration[]>([
              QUERYKEYS.INTEGRATIONS,
              activeCompany?.id,
            ])
            ?.find((integration) => integration.id === integrationObject.id);

          const integrationDoneFetching =
            relevantIntegration?.latest_fetch_date !== null &&
            previousFetchDate !== integrationObject.latest_fetch_date;

          if (
            integrationDoneFetching // handle refetching only if the latest fetch date has been updated
          ) {
            clearInterval(refetchInterval);
            setIntegrationSyncDate(integrationObject.id, ''); // reset the value of the latest fetch date
            queryClient.invalidateQueries().then(() => {
              toast.success(translate('fetching_alert_message'));
            });
          }
        });
    }, second * 1000);

    // Return cleanup function
    return () => {
      if (refetchInterval) {
        clearInterval(refetchInterval);
      }
    };
  };

  return refetchIntegrationsAndRelevantData;
};

export default useRefetchIntegrations;
