/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getCookie } from 'cookies-next';

import { variables } from '@/styles/variables/constant';
import { apiEndpoints } from '@/utils/apis/apis';
import { BASE_URL } from '@/utils/AppConfig';

import { handleAxiosError } from './APIUtilsHelper';

const excludedAuthRoutes = [
  apiEndpoints.LOGIN,
  apiEndpoints.SIGN_UP,
  apiEndpoints.GOOGLE_AUTH,
  apiEndpoints.GET_ALL_COMPANIES,
  apiEndpoints.NEW_TOKEN,
  apiEndpoints.GET_APIDECK_VAULT_TOKEN,
  apiEndpoints.USERME,
  apiEndpoints.SEND_ERROR_REPORT,
];

type AuthToken = {
  access_token: string;
  token_type?: string;
};

export const configureAxios = (
  { access_token, token_type = 'bearer' }: AuthToken,
  activeCompany: number | null = null,
) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `${token_type} ${access_token}`,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const url = config.url as string;
      if (!excludedAuthRoutes.includes(url?.split('?')[0] as string)) {
        config.url = `${activeCompany}/${config.url}`;
      }

      config.headers['Content-Type'] =
        config.url === 'auth/login'
          ? 'application/x-www-form-urlencoded'
          : 'application/json';

      config.headers.Authorization = `${token_type} ${access_token}`;

      return config;
    },
    (error) => {
      handleAxiosError(error);
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      /**
       * Handles Axios errors by passing the error
       * to the handleAxiosError error handler function.
       */
      handleAxiosError(error);
      return Promise.reject(error);
    },
  );

  return instance;
};

/**
 * This function creates and returns an Axios instance configured with authentication and company details.
 * It retrieves the authentication token and active company details from cookies.
 *
 * @returns {AxiosInstance} - A configured Axios instance.
 */
export const clientAxios = () => {
  // Retrieve the authentication token from cookies.
  const authToken = getCookie(variables.lucid_user)
    ? JSON.parse(getCookie(variables.lucid_user) as string)
    : {};

  // Retrieve the active company details from cookies.
  const activeCompany = getCookie(variables.lucid_user_active_company)
    ? JSON.parse(getCookie(variables.lucid_user_active_company) as string)
    : null;

  // Configure and return an Axios instance with the authentication token and active company ID.
  return configureAxios(authToken, activeCompany?.id);
};

/**
 * This function creates and returns an Axios instance configured with authentication and company details.
 * It retrieves the authentication token from cookies and uses a provided company ID.
 *
 * @param {any} req - The request object, used to access cookies.
 * @param {number} company - The ID of the company to be used in the Axios configuration.
 * @returns {AxiosInstance} - A configured Axios instance.
 */
export const serverAxios = (req: any, company: number) => {
  // Retrieve the authentication token from cookies.
  const authToken = getCookie(variables.lucid_user, req)
    ? JSON.parse(getCookie(variables.lucid_user, req) as string)
    : {};

  // Configure and return an Axios instance with the authentication token and provided company ID.
  return configureAxios(authToken, company);
};
