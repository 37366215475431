import { useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { ApplyAIPromptsToEmployees } from '@/utils/AILogic/AIDataProcessing';
import type { Employee } from '@/utils/types/HiringTypes';

import { useEmployeesQuery } from './useEmployees';
import { usePrompts } from './usePrompts';

/**
 * This function is used to apply calculations to employees, including AI prompts changing the employees.
 * @returns a list of updated employees to run calculations on
 */
export const useProcessedBranchEmployees = () => {
  const { employeesByBranch, isLoading, error, refetch, isSuccess } =
    useEmployeesQuery();

  let employees = (employeesByBranch as Employee[]) || [];
  const { currentDate, activeBranch } = useZustandStore(
    useShallow((state: ZustandState) => ({
      currentDate: state.currentDate,
      activeBranch: state.activeBranch,
    })),
  );

  const { hiringPrompts } = usePrompts();

  const { setProcessedBranchEmployees } = DataStore(
    useShallow((state: DataStoreState) => ({
      setProcessedBranchEmployees: state.setProcessedBranchEmployees,
    })),
  );

  const [modifiedEmployeeIdsList, setModifiedEmployeeIds] = useState<number[]>(
    [],
  );

  employees = useMemo(() => {
    if (hiringPrompts?.length === 0) {
      return employeesByBranch || [];
    }
    const { result, modifiedEmployeeIds } = ApplyAIPromptsToEmployees(
      employeesByBranch,
      hiringPrompts,
    );
    setModifiedEmployeeIds(modifiedEmployeeIds);
    setProcessedBranchEmployees(result);
    return result;
  }, [activeBranch, currentDate, employeesByBranch, hiringPrompts, isLoading]);

  return {
    employees,
    isLoading,
    error,
    refetch,
    modifiedEmployeeIdsList,
    isSuccess,
  };
};
